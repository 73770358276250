/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'photo-camera': {
    width: 24,
    height: 21,
    viewBox: '0 0 24 21',
    data: '<g _fill="#F64976" fill-rule="nonzero"><path pid="0" d="M23.48.53a1.647 1.647 0 00-1.21-.504H1.713C1.24.026.836.194.502.53A1.662 1.662 0 000 1.746v17.206c0 .475.167.88.502 1.216.335.337.738.505 1.211.505H22.27c.473 0 .877-.168 1.212-.505.334-.336.502-.741.502-1.216V1.746c0-.475-.168-.88-.502-1.216zM3.427.886h5.14v1.72h-5.14V.886zM22.27 18.952H1.713v-1.72H22.27v1.72zM6.852 11.236c0-1.425.502-2.641 1.506-3.65 1.004-1.007 2.215-1.511 3.633-1.511 1.419 0 2.63.504 3.634 1.512 1.003 1.008 1.505 2.224 1.505 3.65 0 1.424-.502 2.64-1.505 3.649-1.004 1.008-2.215 1.512-3.634 1.512-1.418 0-2.63-.504-3.633-1.512-1.004-1.008-1.506-2.225-1.506-3.65zM22.27 3.601v1.586H1.713v-1.72h8.619l.856-1.72H22.27V3.6z"/><path pid="1" d="M14.414 13.67a3.323 3.323 0 001.003-2.434c0-.95-.334-1.76-1.003-2.433a3.293 3.293 0 00-2.423-1.008c-.946 0-1.753.336-2.422 1.008a3.323 3.323 0 00-1.004 2.433c0 .95.335 1.761 1.004 2.433a3.294 3.294 0 002.422 1.008c.946 0 1.753-.336 2.423-1.008zm-3.333-3.375c-.25.251-.375.556-.375.914a.42.42 0 01-.12.31.415.415 0 01-.308.12.416.416 0 01-.308-.12.42.42 0 01-.12-.31c0-.591.21-1.097.629-1.519.42-.42.923-.631 1.512-.631.125 0 .228.04.308.12.08.081.12.184.12.31a.42.42 0 01-.12.31.416.416 0 01-.308.12c-.357 0-.66.125-.91.376z"/></g>'
  }
})
