import GENRES_QUERY from '~/apollo/queries/metadata/genres'
import SITES_QUERY from '~/apollo/queries/metadata/sites'
// import _get from 'lodash.get'

export default {
  data() {
    const ret = {}
    if (this.$options.prefetchMetadata) {
      for (const mtype of this.$options.prefetchMetadata) {
        ret[mtype] = []
      }
    }
    return ret
  },
  metadata: {
    $_metadata_getGenres(apollo) {
      apollo.addSmartQuery('genres', {
        query: GENRES_QUERY,
        fetchPolicy: 'cache-first',
        variables() {
          const data = {
            limit: 1000,
          }
          if (!this.$route.path.startsWith('/admin')) {
            data.where = {
              genreSites: {
                siteId: {
                  _eq: process.env.AR_SITE_ID,
                },
              },
            }
          }
          return data
        },
        update(data) {
          return data.genres
        },
      })
    },
    $_metadata_getSites(apollo) {
      apollo.addSmartQuery('sites', {
        query: SITES_QUERY,
        fetchPolicy: 'cache-first',
        update(data) {
          return data.sites
        },
      })
    },
  },
  methods: {},
  created() {
    if (this.$options.prefetchMetadata) {
      for (const mtype of this.$options.prefetchMetadata) {
        const ref = `$_metadata_get${mtype
          .charAt(0)
          .toUpperCase()}${mtype.substring(1)}`
        this.$options.metadata[ref](this.$apollo)
      }
    }
  },
}
