// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JoinBanner-JoinBanner--2o2_9jh7{display:flex;justify-content:center;align-items:center;height:60px;background:#ca0413;color:#fff!important;text-shadow:1px 1px 2px rgba(0,0,0,.2);font-size:24px;text-decoration:none;border-radius:5px;padding:10px 15px;font-weight:600}.JoinBanner-JoinBanner--2o2_9jh7:hover{background:#e30415}@media (max-width:767px){.JoinBanner-JoinBanner--2o2_9jh7{font-size:18px;height:auto;padding:20px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"JoinBanner": "JoinBanner-JoinBanner--2o2_9jh7"
};
module.exports = ___CSS_LOADER_EXPORT___;
