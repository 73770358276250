// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Container-Container--qpmr0O4j{padding-left:10px;padding-right:10px;max-width:1170px;margin:0 auto}.Container-Container_small--1fMC3iL3{max-width:600px}.Container-Container_medium--1xeLPFxk{max-width:980px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "Container-Container--qpmr0O4j",
	"Container_small": "Container-Container_small--1fMC3iL3",
	"Container_medium": "Container-Container_medium--1xeLPFxk"
};
module.exports = ___CSS_LOADER_EXPORT___;
