<template>
  <div :class="$style['InlineElement']">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'InlineElement',
}
</script>

<style lang="scss" module>
.InlineElement {
  display: inline-flex;
  margin-right: 7px;
  margin-bottom: 7px;
}
</style>
