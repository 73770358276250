<template>
  <div :class="$style['ContentSectionPagination']">
    <Container>
      <slot />
    </Container>
  </div>
</template>

<script>
import Container from '../Container'
export default {
  name: 'ContentSectionPagination',
  components: {
    Container,
  },
}
</script>
<style lang="scss" module>
.ContentSectionPagination {
  padding: 30px 0;
  margin-bottom: 30px;
  text-align: center;
}
</style>
