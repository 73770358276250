<template>
  <div :class="this.$style['ContentSectionHead']">
    <div :class="this.$style['ContentSectionHead__content']">
      <div v-if="title" :class="this.$style['ContentSectionHead__item']">
        <ContentSectionTitle :title="title" :theme="theme" />
      </div>
      <div v-if="count" :class="this.$style['ContentSectionHead__item']">
        <Badge v-if="count" :text="count" :theme="theme" />
      </div>
      <slot />
    </div>
    <div v-if="!!$slots.right || buttonText" :class="this.$style['ContentSectionHead__right']">
      <Button v-if="buttonText && link" color="primary" :width="$mq === 'mobile' ? 'full' : 'large'" plain @click="onButtonClick">
        {{ buttonText }}
      </Button>
      <slot name="right" />
    </div>
  </div>
</template>

<script>
import Button from '../Button'
import Badge from '../Badge'
import ContentSectionTitle from './ContentSectionTitle'
export default {
  name: 'ContentSectionHead',
  components: {
    Button,
    Badge,
    ContentSectionTitle,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    count: {
      type: String,
      default: null,
    },
    buttonText: {
      type: String,
      default: null,
    },
    link: {
      type: String,
      default: null,
    },
    theme: {
      type: String,
      default: process.env.theme || 'light',
    },
  },
  methods: {
    onButtonClick() {
      if (!this.link) return
      this.$router.push(this.link)
    },
  },
}
</script>
<style lang="scss" module>
.ContentSectionHead {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  &__content {
    display: flex;
    flex: 1 1 auto;
    line-height: 1;
  }
  &__right {
    flex-shrink: 0;
  }
  &__item {
    margin-right: 10px;
  }
  @media (max-width: $--sm - 1) {
    display: block;
    &__right {
      margin-top: 15px;
    }
  }
}
</style>
