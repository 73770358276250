<template>
  <ContentSection size="large" width="medium">
    <ContentSectionHead :title="title" />
    <ContentSectionText>
      <slot />
    </ContentSectionText>
  </ContentSection>
</template>

<script>
import {
  ContentSection,
  ContentSectionHead,
  ContentSectionText,
} from '../ContentSection'
export default {
  name: 'ContentPage',
  components: {
    ContentSection,
    ContentSectionHead,
    ContentSectionText,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
  },
}
</script>
