// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeaderUser-HeaderUser--G_-QMUti{display:flex}.HeaderUser-HeaderUser--G_-QMUti,.HeaderUser-HeaderUser__join--3Cn4LLtX{margin-left:15px}.HeaderUser-HeaderUser__user--3as8mYtK{background:none;border:none;outline:none;cursor:pointer}.HeaderUser-HeaderUser__user--3as8mYtK svg{transition-duration:.2s}.HeaderUser-HeaderUser_theme_light--1tsoLl3w .HeaderUser-HeaderUser__user--3as8mYtK svg{fill:hsla(0,0%,46.7%,.5)}.HeaderUser-HeaderUser_theme_light--1tsoLl3w .HeaderUser-HeaderUser__user--3as8mYtK:hover svg{fill:#ca0413!important}.HeaderUser-HeaderUser_theme_dark--2YTed5-r .HeaderUser-HeaderUser__user--3as8mYtK svg{fill:hsla(0,0%,100%,.5)}.HeaderUser-HeaderUser_theme_dark--2YTed5-r .HeaderUser-HeaderUser__user--3as8mYtK:hover svg{fill:#fff}@media (max-width:767px){.HeaderUser-HeaderUser--G_-QMUti{margin-left:0}.HeaderUser-HeaderUser__join--3Cn4LLtX{display:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HeaderUser": "HeaderUser-HeaderUser--G_-QMUti",
	"HeaderUser__join": "HeaderUser-HeaderUser__join--3Cn4LLtX",
	"HeaderUser__user": "HeaderUser-HeaderUser__user--3as8mYtK",
	"HeaderUser_theme_light": "HeaderUser-HeaderUser_theme_light--1tsoLl3w",
	"HeaderUser_theme_dark": "HeaderUser-HeaderUser_theme_dark--2YTed5-r"
};
module.exports = ___CSS_LOADER_EXPORT___;
