<template>
  <div v-if="photos && photos.length" :class="$style['Gallery']">
    <div :class="$style['Gallery__main']">
      <a
        v-for="img in photos"
        :key="img.href"
        :class="$style['Gallery__item']"
        :href="hardLink || img.href"
        :target="target"
      >
        <img :data-src="img.src" alt="" class="lazyload">
      </a>
    </div>
    <div v-if="showNav" :class="$style['Gallery__nav']">
      <el-button-group>
        <el-button
          type="primary"
          :disabled="disabledPrev"
          @click="$emit('prev')"
        >
          <i class="el-icon-arrow-left el-icon-left" />
          Previous Page
        </el-button>
        <el-button type="primary" :disabled="disabledNext" @click="$emit('next')">
          Next Page
          <i class="el-icon-arrow-right el-icon-right" />
        </el-button>
      </el-button-group>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Gallery',
  props: {
    photos: {
      type: Array,
      default: null,
    },
    target: {
      type: String,
      default: '_blank',
    },
    group: {
      type: String,
      default: 'photos',
    },
    hardLink: {
      type: String,
      default: null,
    },
    showNav: {
      type: Boolean,
      default: false,
    },
    disabledPrev: {
      type: Boolean,
      default: false,
    },
    disabledNext: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style lang="scss" module>
.Gallery {
  margin-left: -10px;
  margin-right: -10px;
  &__main {
    display: flex;
    flex-wrap: wrap;
  }
  &__item {
    width: 20%;
    max-width: 300px;
    height: 200px;
    overflow: hidden;
    padding: 10px;
    transition-duration: 0.2s;
    &:hover {
      opacity: 0.8;
    }
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  &__nav {
    text-align: center;
    padding: 30px 0;
  }
  @media (max-width: $--sm) {
    &__item {
      width: 50%;
      padding: 20px 10px;
    }
  }
}
</style>
