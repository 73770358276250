<template>
  <div v-if="scenes" :class="$style['ScenesList']">
    <div v-for="scene in scenes" :key="scene.id" :class="$style['ScenesList__el']">
      <ScenesListItem :scene="scene" :theme="theme" :show-site="showSite" :custom-scene-url="customSceneUrl" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScenesList',
  components: {
    ScenesListItem: () => import('./ScenesListItem'),
  },
  props: {
    scenes: {
      type: Array,
      default: null,
    },
    theme: {
      type: String,
      default: process.env.theme || 'light',
    },
    showSite: {
      type: Boolean,
      default: false,
    },
    section: {
      type: String,
      default: null,
    },
    customSceneUrl: {
      type: String,
      default: null,
    },
  },
}
</script>
<style lang="scss" module>
.ScenesList {
  &__el {
    padding: 10px 0 20px 0;
  }
  @media (min-width: $--sm) {
    display: flex;
    margin-left: -10px;
    margin-right: -10px;
    flex-wrap: wrap;
    &__el {
      padding: 0 0 20px 10px;
      flex: 1 1 50%;
      width: 50%;
      max-width: 50%;
    }
  }
  @media (min-width: $--md) {
    &__el {
      flex: 1 1 33.333333%;
      width: 33.333333%;
      max-width: 33.333333%;
    }
  }
}
</style>
