/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'refresh': {
    width: 24,
    height: 26,
    viewBox: '0 0 24 26',
    data: '<g _fill="#F64976" fill-rule="evenodd"><path pid="0" d="M2.07 10.786c.824 0 1.569-.499 1.888-1.264C5.274 6.365 8.37 4.141 11.98 4.141c1.99 0 3.813.686 5.275 1.824l-1.146.178a.9.9 0 00-.23 1.71l6.303 2.853a.89.89 0 00.85-.064.903.903 0 00.41-.752l.034-6.988a.891.891 0 00-1.638-.503l-.87 1.314A12.736 12.736 0 0011.98 0C6.674 0 2.112 3.26.167 7.895a2.09 2.09 0 00.18 1.959c.383.581 1.03.932 1.723.932zM23.612 15.952a2.048 2.048 0 00-3.61.332c-1.317 3.156-4.413 5.38-8.022 5.38a8.555 8.555 0 01-5.276-1.823l1.146-.179a.9.9 0 00.23-1.71L1.778 15.1a.89.89 0 00-.851.064.903.903 0 00-.41.753l-.033 6.988a.891.891 0 001.638.503l.869-1.314a12.736 12.736 0 008.989 3.712c5.306 0 9.867-3.26 11.813-7.894a2.09 2.09 0 00-.18-1.96z"/></g>'
  }
})
