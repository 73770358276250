// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CtaBannerFeatures-CtaBannerFeatures--1H-6o1UG{flex-wrap:wrap;margin-bottom:40px}.CtaBannerFeatures-CtaBannerFeatures__icon--24UIh1BW{margin-right:7px}.CtaBannerFeatures-CtaBannerFeatures__title--k3cYbK5q{font-size:14px;font-weight:700;padding-top:2px}.CtaBannerFeatures-CtaBannerFeatures__item--1NeNQo4A{display:flex;flex:1 1 33.333333%;padding-right:10px;line-height:1}@media (min-width:768px){.CtaBannerFeatures-CtaBannerFeatures--1H-6o1UG{display:flex}}@media (max-width:767px){.CtaBannerFeatures-CtaBannerFeatures--1H-6o1UG{margin-bottom:20px}.CtaBannerFeatures-CtaBannerFeatures__item--1NeNQo4A{margin-bottom:15px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CtaBannerFeatures": "CtaBannerFeatures-CtaBannerFeatures--1H-6o1UG",
	"CtaBannerFeatures__icon": "CtaBannerFeatures-CtaBannerFeatures__icon--24UIh1BW",
	"CtaBannerFeatures__title": "CtaBannerFeatures-CtaBannerFeatures__title--k3cYbK5q",
	"CtaBannerFeatures__item": "CtaBannerFeatures-CtaBannerFeatures__item--1NeNQo4A"
};
module.exports = ___CSS_LOADER_EXPORT___;
