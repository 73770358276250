import { client } from '~/apollo'
// import bugsnagClient from '~/plugins/bugsnag-client'
import CURRENT_USER_QUERY from '~/apollo/queries/currentUser'

const excludedRoutes = ['logout']

const handleToken = () => {
  const token = window.localStorage.getItem('app-user-token')
  if (!token) return null // this will use the default redirect
  const currentToken = JSON.parse(token)
  if (!currentToken) return null
  if (!currentToken.expiresAt) return null

  const currentTime = new Date()
  const tokenExpiresAt = new Date(currentToken.expiresAt)
  const diff = tokenExpiresAt - currentTime
  if (diff <= 0) return 'logout'
  return null
}

// Make sure the currentUser can view the current section they're in
const canAccessSection = (route, currentUser) => {
  const baseDir = route.path.split('/')[1]

  switch (baseDir) {
    case 'admin':
      return currentUser.isAdmin
  }

  return true
}

const canAccessSubSection = (route, currentUser) => {
  // const subSection = route.path.split('/')[2]

  return true
}

export default ({ redirect, route, app }) => {
  // dont load up users for non-logged in pages
  if (excludedRoutes.includes(route.name)) return null

  const status = handleToken()
  if (status) {
    redirect(`/${status}`)
    return null
  }

  const { currentUser } = client.readQuery({
    query: CURRENT_USER_QUERY,
  })

  if (currentUser && app.$bugsnag) {
    app.$bugsnag.setUser(
      currentUser.id,
      currentUser.email,
      `${currentUser.firstName} ${currentUser.lastName}`,
    )
  }

  if (
    !canAccessSection(route, currentUser) ||
    !canAccessSubSection(route, currentUser)
  ) {
    redirect('/')
    return
  }

  return null
}
