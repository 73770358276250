<template>
  <div v-if="category" :class="rootClasses">
    <nuxt-link :to="category.urls.url">
      <span :class="$style['CategoriesListItem__img']">
        <img :data-src="category.urls.thumbnailUrl" :alt="category.name" class="lazyload">
      </span>
      <span :class="$style['CategoriesListItem__title']">
        {{ category.name }}
      </span>
    </nuxt-link>
  </div>
</template>

<script>
export default {
  name: 'CategoriesListItem',
  props: {
    category: {
      type: Object,
      default: null,
    },
    theme: {
      type: String,
      default: process.env.theme || 'light',
    },
  },
  computed: {
    rootClasses() {
      return {
        [this.$style.CategoriesListItem]: true,
        [this.$style[`CategoriesListItem_theme_${this.theme}`]]: this.theme,
      }
    },
  },
}
</script>
<style lang="scss" module>
.CategoriesListItem {
  $c: &;
  display: block;
  a {
    text-decoration: none !important;
  }
  &__img {
    display: block;
    line-height: 0;
    background: $--color-primary;
    img {
      width: 100%;
      transition-duration: 0.3s;
    }
  }
  &:hover {
    img {
      opacity: 0.9;
    }
  }
  &__title {
    display: block;
    margin-top: 10px;
    font-size: 18px;
    font-weight: $--fw-bold;
  }
  &_theme {
    &_light {
      #{$c}__title {
        color: $--text-color;
      }
    }
    &_dark {
      #{$c}__title {
        color: $--color-grey-lightest;
      }
    }
  }
}
</style>
