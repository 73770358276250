/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'video-camera': {
    width: 24,
    height: 22,
    viewBox: '0 0 24 22',
    data: '<g _fill="#F64976" fill-rule="evenodd"><path pid="0" d="M23.842 10.875a.976.976 0 00-1.355-.348L19.2 12.44l-.836.493v4.582l.779.464c.028 0 .028.029.057.029l3.23 1.884a.884.884 0 00.547.174c.548 0 .98-.435.98-.986v-3.885l.029-3.828c0-.174-.029-.348-.144-.493z"/><path pid="1" d="M4.642 9.396c2.565 0 4.641-2.088 4.641-4.669 0-2.58-2.076-4.669-4.641-4.669C2.076.058 0 2.146 0 4.727c-.029 2.58 2.076 4.669 4.642 4.669zm0-6.583c1.066 0 1.902.87 1.902 1.914a1.906 1.906 0 01-1.902 1.914c-1.038 0-1.903-.87-1.903-1.914-.029-1.073.836-1.914 1.903-1.914z" fill-rule="nonzero"/><path pid="2" d="M16.404 9.657a5.499 5.499 0 01-2.48.609 5.368 5.368 0 01-2.998-.9H7.64a5.368 5.368 0 01-2.998.9c-.894 0-1.73-.203-2.48-.61A2.27 2.27 0 001.038 11.6v7.076a2.35 2.35 0 002.335 2.348h11.849c1.297 0 2.335-1.073 2.335-2.348V11.6c-.029-.812-.49-1.566-1.153-1.943z"/><path pid="3" d="M13.925 9.396c2.565 0 4.641-2.088 4.641-4.669 0-2.58-2.076-4.669-4.641-4.669-2.566 0-4.642 2.088-4.642 4.669 0 2.58 2.076 4.669 4.642 4.669zm0-6.583c1.066 0 1.902.87 1.902 1.914a1.906 1.906 0 01-1.902 1.914c-1.038 0-1.903-.87-1.903-1.914 0-1.073.836-1.914 1.903-1.914z" fill-rule="nonzero"/></g>'
  }
})
