// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FiltersBox-FiltersBox--nu02QN4d{cursor:pointer;border:2px solid rgba(0,0,0,.1);border-radius:3px;padding:10px;display:flex;justify-content:center;align-items:center;flex-direction:column}.FiltersBox-FiltersBox--nu02QN4d:hover{border-color:rgba(0,0,0,.2)}.FiltersBox-FiltersBox_active--2QEb8smd{border-color:#ca0413!important}.FiltersBox-FiltersBox__title--gG3AyD1q{color:#777;margin-top:10px;font-size:14px}.FiltersBox-FiltersBox__img--2c3EtTRv{display:flex;height:50px;align-items:center;justify-content:center}.FiltersBox-FiltersBox__img--2c3EtTRv img{max-height:50px;max-width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FiltersBox": "FiltersBox-FiltersBox--nu02QN4d",
	"FiltersBox_active": "FiltersBox-FiltersBox_active--2QEb8smd",
	"FiltersBox__title": "FiltersBox-FiltersBox__title--gG3AyD1q",
	"FiltersBox__img": "FiltersBox-FiltersBox__img--2c3EtTRv"
};
module.exports = ___CSS_LOADER_EXPORT___;
