import state from '~/apollo/state'

export default {
  computed: {
    isLoggedIn() {
      return !!this.currentUser.id
    },
    currentUser() {
      return state.currentUser
    },
  },
}
