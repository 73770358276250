import VueApollo from 'vue-apollo'
import { ApolloClient } from 'apollo-client'
import { ApolloLink, Observable } from 'apollo-link'
// import { BatchHttpLink } from 'apollo-link-batch-http'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'

import cloneDeep from 'lodash.clonedeep'
import state from './state'

import SET_TOKEN_MUTATION from '~/apollo/mutations/actions/setToken'
import CURRENT_USER_QUERY from '~/apollo/queries/currentUser'

// import resolvers
import { resolvers as actionResolvers } from '~/apollo/resolvers/actions'

const customFetch = (uri, options) => {
  const promise = fetch(uri, options)
  promise
    .then(response => {
      const refreshToken = response.headers.get('Refresh-Token')
      const refreshTokenExpiresAt = response.headers.get(
        'Refresh-Token-Expires-At',
      )
      if (refreshToken && refreshTokenExpiresAt) {
        const token = { jwt: refreshToken, expiresAt: refreshTokenExpiresAt }

        client.mutate({
          mutation: SET_TOKEN_MUTATION,
          variables: { token },
        })
      }
      return response
    })
    .catch(e => {
      throw e
    })
  return promise
}

const httpLink = new HttpLink({
  uri: process.env.GRAPHQL_URL,
  fetch: customFetch,
})

const request = ({ setContext }) => {
  const headers = window.location.pathname.startsWith('/admin')
    ? {}
    : {
        'x-site-id': process.env.AR_SITE_ID,
      }
  const locationHash = window.location && window.location.hash

  const currentToken = JSON.parse(window.localStorage.getItem('app-user-token'))

  // If on login page do not include any auth headers
  if (currentToken) {
    headers.authorization = `Bearer ${currentToken.jwt}`
  }

  // don't use the token if the user is on login
  const isLoginPage = locationHash === '#/login'
  if (!isLoginPage) {
    setContext({ headers })
  }
}

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle = null
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          })
        })
        .catch(observer.error.bind(observer))

      return () => {
        if (handle) handle.unsubscribe()
      }
    }),
)

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    if (process.env.APP_ENV !== 'production') {
      console.log('[Graphql error]:', graphQLErrors)
    }
    graphQLErrors.map(({ type, message, locations, path, fields }) => {
      // If the user has some middleware auth failure, send them back to login with reason
      if (fields && fields.token && fields.token[0] === 'middleware_auth') {
        console.log(fields)
        location.href = `/logout?error=${type}`
      }
    })
  }
  if (networkError && process.env.APP_ENV !== 'production')
    console.log('[Network error]:', networkError)
})

const cache = new InMemoryCache()

// set defaults
const data = {
  currentUser: null,
}
cache.writeData({ data })
const sessionCurrentUser = JSON.parse(
  window.localStorage.getItem('app-current-user'),
)

if (sessionCurrentUser) {
  cache.writeQuery({
    query: CURRENT_USER_QUERY,
    data: {
      currentUser: JSON.parse(window.localStorage.getItem('app-current-user')),
    },
  })
}

const link = ApolloLink.from([requestLink, errorLink, httpLink])

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
  },
  query: {
    fetchPolicy: 'cache-and-network',
  },
}

export const client = new ApolloClient({
  link,
  cache,
  resolvers: { ...actionResolvers },
  defaultHttpLink: false,
  connectToDevTools: process.env.NODE_ENV !== 'production',
  defaultOptions,
})
client.onResetStore(() => cache.writeData({ data }))

// watch and update local storage
client.watchQuery({ query: CURRENT_USER_QUERY }).subscribe(({ data }) => {
  if (!data || !data.currentUser) return
  state.currentUser = cloneDeep(data.currentUser)
  window.localStorage.setItem(
    'app-current-user',
    JSON.stringify(data.currentUser),
  )
})

export const purgeCache = async () => {
  await client.resetStore()
  await window.localStorage.clear()
  await window.localStorage.setItem('hasAcceptedDisclaimer', true)
  state.currentUser = {}
  return true
}

export const apolloProvider = new VueApollo({
  defaultClient: client,
})

export default client
