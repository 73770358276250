<template>
  <div :class="$style['FiltersButton']">
    <el-badge :value="badge ? badge : null">
      <Button @click="$emit('click')">
        {{ title }}
        <i class="el-icon-arrow-down el-icon--right" />
      </Button>
    </el-badge>
  </div>
</template>

<script>
import Button from '../Button'
export default {
  name: 'FiltersButton',
  components: {
    Button,
  },
  props: {
    badge: {
      type: [Number, String],
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
  },
}
</script>
<style lang="scss" module>
.FiltersButton {
  position: relative;
  z-index: 1000;
  margin-left: 10px;
  @media (max-width: $--sm) {
    margin-left: 0;
    margin-bottom: 10px;
    > div,
    button {
      display: block;
      width: 100%;
    }
  }
}
</style>
