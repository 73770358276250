<template>
  <h1 :class="rootClasses">
    {{ title }}
    <slot />
  </h1>
</template>

<script>
export default {
  name: 'ContentSectionTitle',
  props: {
    title: {
      type: String,
      default: null,
    },
    theme: {
      type: String,
      default: process.env.theme || 'light',
    },
  },
  computed: {
    rootClasses() {
      return {
        [this.$style.ContentSectionTitle]: true,
        [this.$style[`ContentSectionTitle_theme_${this.theme}`]]: this.theme,
      }
    },
  },
}
</script>
<style lang="scss" module>
.ContentSectionTitle {
  display: inline-block;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: $--fw-medium;
  margin: 0;
  padding: 0;
  &_theme {
    &_light {
      color: $--text-color;
    }
    &_dark {
      color: #fff;
    }
  }
}
</style>
