// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CategoriesListItem-CategoriesListItem--1ocxB9l2{display:block}.CategoriesListItem-CategoriesListItem--1ocxB9l2 a{text-decoration:none!important}.CategoriesListItem-CategoriesListItem__img--2N7ygWiQ{display:block;line-height:0;background:#ca0413}.CategoriesListItem-CategoriesListItem__img--2N7ygWiQ img{width:100%;transition-duration:.3s}.CategoriesListItem-CategoriesListItem--1ocxB9l2:hover img{opacity:.9}.CategoriesListItem-CategoriesListItem__title--34jlEZ2w{display:block;margin-top:10px;font-size:18px;font-weight:700}.CategoriesListItem-CategoriesListItem_theme_light--31BMUeFg .CategoriesListItem-CategoriesListItem__title--34jlEZ2w{color:#444}.CategoriesListItem-CategoriesListItem_theme_dark--3Owm-Ywo .CategoriesListItem-CategoriesListItem__title--34jlEZ2w{color:#f6f8fa}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CategoriesListItem": "CategoriesListItem-CategoriesListItem--1ocxB9l2",
	"CategoriesListItem__img": "CategoriesListItem-CategoriesListItem__img--2N7ygWiQ",
	"CategoriesListItem__title": "CategoriesListItem-CategoriesListItem__title--34jlEZ2w",
	"CategoriesListItem_theme_light": "CategoriesListItem-CategoriesListItem_theme_light--31BMUeFg",
	"CategoriesListItem_theme_dark": "CategoriesListItem-CategoriesListItem_theme_dark--3Owm-Ywo"
};
module.exports = ___CSS_LOADER_EXPORT___;
