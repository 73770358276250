<template>
  <div :class="$style['FiltersColumns']">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FiltersColumns',
}
</script>
<style lang="scss" module>
.FiltersColumns {
  margin-left: -10px;
  margin-right: -10px;
  overflow-y: auto;
  @media (min-width: $--sm + 1) {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
