/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dislike': {
    width: 16,
    height: 16,
    viewBox: '0 0 241.66 241.66',
    data: '<path pid="0" d="M210.323 17.93h-17.898c-9.01 0-16.066-5.165-26.848-8.949C150.811 3.865 128.756.025 90.766.025 84.122.025 63.516 0 63.516 0c-6.309 0-11.377 2.882-15.035 6.363-1.392 1.323-2.844 3.245-3.465 6.994-.101.582-.21 3.017-.193 3.346-.478 10.729 6.008 14.614 9.682 15.835-.101.034-.033.126-.235.117l-11.662-.522c-10.352-.472-20.572 6.986-20.572 19.669 0 10.517 8.524 17.933 18.844 18.439l-6.184-.287c-10.352-.455-19.103 7.695-19.582 18.22-.453 10.526 7.567 19.433 17.913 19.906-10.345-.472-19.121 7.677-19.573 18.203-.454 10.526 6.821 19.99 17.174 20.444l68.73 8.63s-14.324 23.959-14.324 59.455c0 23.664 16.905 26.848 26.848 26.848 7.821.002 9.927-15.151 9.927-15.151h.016c1.77-9.717 4.077-18.203 12.091-33.827 8.968-17.512 21.184-15.869 35.446-31.467 2.517-2.747 5.898-7.281 9.195-12.86.269-.295.521-.708.764-1.289.293-.69.646-1.172.956-1.812a102.669 102.669 0 001.61-3.059c8.826-8.827 22.579-7.925 28.435-7.925 11.746 0 17.898-6.825 17.898-17.898l.005-81.828c.002-12.423-5.082-16.614-17.902-16.614z" fill-rule="evenodd" clip-rule="evenodd"/>'
  }
})
