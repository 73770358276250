// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Gallery-Gallery--vgTQVqDy{margin-left:-10px;margin-right:-10px}.Gallery-Gallery__main--235_qfYd{display:flex;flex-wrap:wrap}.Gallery-Gallery__item--s78oQNYs{width:20%;max-width:300px;height:200px;overflow:hidden;padding:10px;transition-duration:.2s}.Gallery-Gallery__item--s78oQNYs:hover{opacity:.8}.Gallery-Gallery__item--s78oQNYs img{width:100%;-o-object-fit:cover;object-fit:cover}.Gallery-Gallery__nav--2f56zf7l{text-align:center;padding:30px 0}@media (max-width:768px){.Gallery-Gallery__item--s78oQNYs{width:50%;padding:20px 10px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Gallery": "Gallery-Gallery--vgTQVqDy",
	"Gallery__main": "Gallery-Gallery__main--235_qfYd",
	"Gallery__item": "Gallery-Gallery__item--s78oQNYs",
	"Gallery__nav": "Gallery-Gallery__nav--2f56zf7l"
};
module.exports = ___CSS_LOADER_EXPORT___;
