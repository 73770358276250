<template>
  <h2 :class="$style['CtaBannerTitle']">
    <slot />
  </h2>
</template>

<script>
export default {
  name: 'CtaBannerTitle',
}
</script>
<style lang="scss" module>
.CtaBannerTitle {
  font-size: 30px;
  font-weight: $--fw-bold;
  margin: 0 0 40px 0;
  padding: 0;
  @media (max-width: $--sm - 1) {
    font-size: 24px;
    margin-bottom: 20px;
  }
}
</style>
