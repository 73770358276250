// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Header-Header--2bHmErbb{top:0;position:sticky;width:100%;z-index:999}.Header-Header__mobile-button--3Y3sp2DS{display:none}.Header-Header__container--23kDPQCy{height:70px;display:flex;align-items:center}.Header-Header__logo--1t9p_dxy img{max-width:180px;max-height:50px}.Header-Header__content--3vsSJRQn{display:flex;flex:1 1 auto;justify-content:flex-end;align-items:center;font-size:14px;text-transform:uppercase}.Header-Header_theme_light--3VRn_Ogk{background:#fff;box-shadow:0 2px 10px rgba(0,0,0,.1)}.Header-Header_theme_dark--3hcYrmHu{background:#212121}@media (max-width:767px){.Header-Header__logo--1t9p_dxy{flex:1 1 auto;text-align:center}.Header-Header__content--3vsSJRQn{flex:0 0 auto}.Header-Header__mobile-button--3Y3sp2DS{display:flex;background:none;border:none;outline:none;cursor:pointer}.Header-Header__mobile-button--3Y3sp2DS svg{transition-duration:.2s}.Header-Header_theme_light--3VRn_Ogk .Header-Header__mobile-button--3Y3sp2DS svg{fill:hsla(0,0%,46.7%,.5)}.Header-Header_theme_light--3VRn_Ogk .Header-Header__mobile-button--3Y3sp2DS:hover svg{fill:#ca0413!important}.Header-Header_theme_dark--3hcYrmHu .Header-Header__mobile-button--3Y3sp2DS svg{fill:hsla(0,0%,100%,.5)}.Header-Header_theme_dark--3hcYrmHu .Header-Header__mobile-button--3Y3sp2DS:hover svg{fill:#fff}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Header": "Header-Header--2bHmErbb",
	"Header__mobile-button": "Header-Header__mobile-button--3Y3sp2DS",
	"Header__container": "Header-Header__container--23kDPQCy",
	"Header__logo": "Header-Header__logo--1t9p_dxy",
	"Header__content": "Header-Header__content--3vsSJRQn",
	"Header_theme_light": "Header-Header_theme_light--3VRn_Ogk",
	"Header_theme_dark": "Header-Header_theme_dark--3hcYrmHu"
};
module.exports = ___CSS_LOADER_EXPORT___;
