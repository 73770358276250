// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FiltersColumns-FiltersColumns--dkLlsQMl{margin-left:-10px;margin-right:-10px;overflow-y:auto}@media (min-width:769px){.FiltersColumns-FiltersColumns--dkLlsQMl{display:flex;flex-wrap:wrap}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FiltersColumns": "FiltersColumns-FiltersColumns--dkLlsQMl"
};
module.exports = ___CSS_LOADER_EXPORT___;
