<template>
  <div :class="rootClasses">
    <Button v-if="!isLoggedIn" color="primary" plain @click="$emit('login')">
      Log in
    </Button>
    <div v-if="isLoggedIn" :class="$style.HeaderUser__join" @click="$router.push('/logout')">
      <Button color="danger" width="medium">
        Log Out
      </Button>
    </div>
    <div v-else :class="$style.HeaderUser__join">
      <Button color="primary" width="medium" @click="goToJoinNowUrl">
        Join Now
      </Button>
    </div>
  </div>
</template>

<script>
// import Icon from '../Icon'
import Button from '../Button'

import CurrentUserMixin from '~/mixins/currentUser'

export default {
  name: 'HeaderUser',
  components: {
    // Icon,
    Button,
  },
  mixins: [CurrentUserMixin],
  props: {
    theme: {
      type: String,
      default: process.env.theme || 'light',
    },
  },
  computed: {
    rootClasses() {
      return {
        [this.$style.HeaderUser]: true,
        [this.$style[`HeaderUser_theme_${this.theme}`]]: this.theme,
      }
    },
  },
}
</script>
<style lang="scss" module>
.HeaderUser {
  $c: &;
  margin-left: 15px;
  display: flex;
  &__join {
    margin-left: 15px;
  }
  &__user {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    svg {
      transition-duration: 0.2s;
    }
  }
  &_theme {
    &_light #{$c}__user {
      svg {
        fill: rgba($--color-grey, 0.5);
      }
      &:hover {
        svg {
          fill: $--color-primary !important;
        }
      }
    }
    &_dark #{$c}__user {
      svg {
        fill: rgba(#fff, 0.5);
      }
      &:hover {
        svg {
          fill: #fff;
        }
      }
    }
  }
  @media (max-width: $--sm - 1) {
    margin-left: 0;
    &__join {
      display: none;
    }
  }
}
</style>
