// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CtaBannerText-CtaBannerText--3Z9ulbqr{font-size:20px;margin:0 0 40px;padding:0}@media (max-width:767px){.CtaBannerText-CtaBannerText--3Z9ulbqr{font-size:18px;margin-bottom:20px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CtaBannerText": "CtaBannerText-CtaBannerText--3Z9ulbqr"
};
module.exports = ___CSS_LOADER_EXPORT___;
