// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CtaBannerTitle-CtaBannerTitle--2nhmN48_{font-size:30px;font-weight:700;margin:0 0 40px;padding:0}@media (max-width:767px){.CtaBannerTitle-CtaBannerTitle--2nhmN48_{font-size:24px;margin-bottom:20px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CtaBannerTitle": "CtaBannerTitle-CtaBannerTitle--2nhmN48_"
};
module.exports = ___CSS_LOADER_EXPORT___;
