// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PlainText-PlainText--2Ekg-l_V{display:block}.PlainText-PlainText__left--3OTRu4oJ{flex-shrink:0;margin-right:7px}.PlainText-PlainText__right--34CNSre7{flex-shrink:0;margin-left:7px}.PlainText-PlainText__content--q58An3GL{flex:1 1 auto}.PlainText-PlainText_weight_medium--1IJw_yCH{font-weight:600}.PlainText-PlainText_weight_bold--2TbD7RBV{font-weight:700}.PlainText-PlainText_size_small--c6Cf-wX6{font-size:14px}.PlainText-PlainText_size_smaller--1o983W86{font-size:13px}.PlainText-PlainText_size_medium--eKHRcZQc{font-size:18px}.PlainText-PlainText_size_large--1rVlQlhK{font-size:22px}.PlainText-PlainText_size_larger--24R6VQA-{font-size:26px}.PlainText-PlainText_size_largest--391Qa0Uv{font-size:30px}.PlainText-PlainText_color_grey--nqjARKvr{color:#777}.PlainText-PlainText_color_red--3VhOI8z1{color:#f56c6c}.PlainText-PlainText_color_green--2ODa4QK2{color:#67c23a}.PlainText-PlainText_color_orange--10HDGw6B{color:#e6a23c}.PlainText-PlainText_color_primary--2NYb1OkA{color:#ca0413}.PlainText-PlainText_uppercase--12_yFhMQ{text-transform:uppercase}.PlainText-PlainText_center--39zxtlgj{text-align:center}.PlainText-PlainText_right--PntcVg6r{text-align:right}.PlainText-PlainText_nowrap--g6bmF205{white-space:nowrap}.PlainText-PlainText_flex--2dw2lWwT{display:flex}.PlainText-PlainText_inline--2qXlBPNv{display:inline}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PlainText": "PlainText-PlainText--2Ekg-l_V",
	"PlainText__left": "PlainText-PlainText__left--3OTRu4oJ",
	"PlainText__right": "PlainText-PlainText__right--34CNSre7",
	"PlainText__content": "PlainText-PlainText__content--q58An3GL",
	"PlainText_weight_medium": "PlainText-PlainText_weight_medium--1IJw_yCH",
	"PlainText_weight_bold": "PlainText-PlainText_weight_bold--2TbD7RBV",
	"PlainText_size_small": "PlainText-PlainText_size_small--c6Cf-wX6",
	"PlainText_size_smaller": "PlainText-PlainText_size_smaller--1o983W86",
	"PlainText_size_medium": "PlainText-PlainText_size_medium--eKHRcZQc",
	"PlainText_size_large": "PlainText-PlainText_size_large--1rVlQlhK",
	"PlainText_size_larger": "PlainText-PlainText_size_larger--24R6VQA-",
	"PlainText_size_largest": "PlainText-PlainText_size_largest--391Qa0Uv",
	"PlainText_color_grey": "PlainText-PlainText_color_grey--nqjARKvr",
	"PlainText_color_red": "PlainText-PlainText_color_red--3VhOI8z1",
	"PlainText_color_green": "PlainText-PlainText_color_green--2ODa4QK2",
	"PlainText_color_orange": "PlainText-PlainText_color_orange--10HDGw6B",
	"PlainText_color_primary": "PlainText-PlainText_color_primary--2NYb1OkA",
	"PlainText_uppercase": "PlainText-PlainText_uppercase--12_yFhMQ",
	"PlainText_center": "PlainText-PlainText_center--39zxtlgj",
	"PlainText_right": "PlainText-PlainText_right--PntcVg6r",
	"PlainText_nowrap": "PlainText-PlainText_nowrap--g6bmF205",
	"PlainText_flex": "PlainText-PlainText_flex--2dw2lWwT",
	"PlainText_inline": "PlainText-PlainText_inline--2qXlBPNv"
};
module.exports = ___CSS_LOADER_EXPORT___;
