<template>
  <div>
    <FiltersButton :title="buttonTitle" :badge="badge" @click="toggle" />
    <FiltersDropdown
      v-if="isActive"
      :title="dropdownTitle"
      @close="close"
      @apply="apply"
    >
      <slot />
    </FiltersDropdown>
  </div>
</template>

<script>
import FiltersButton from './FiltersButton'
import FiltersDropdown from './FiltersDropdown'
export default {
  name: 'FiltersItem',
  components: {
    FiltersButton,
    FiltersDropdown,
  },
  props: {
    badge: {
      type: [Number, String],
      default: null,
    },
    buttonTitle: {
      type: String,
      default: null,
    },
    dropdownTitle: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isActive: false,
    }
  },
  methods: {
    toggle() {
      this.isActive = !this.isActive
    },
    close() {
      this.isActive = false
      this.$emit('close')
    },
    apply() {
      this.isActive = false
      this.$emit('apply')
    },
  },
}
</script>
