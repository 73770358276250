<template>
  <p :class="$style['CtaBannerText']">
    <slot />
  </p>
</template>

<script>
export default {
  name: 'CtaBannerText',
}
</script>
<style lang="scss" module>
.CtaBannerText {
  font-size: 20px;
  margin: 0 0 40px 0;
  padding: 0;
  @media (max-width: $--sm - 1) {
    font-size: 18px;
    margin-bottom: 20px;
  }
}
</style>
