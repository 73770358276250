// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Filters-Filters--3oGFmBjg{position:relative;flex:1 1 auto;display:flex;align-items:center}.Filters-Filters__clear--poaxMvH8{margin-left:15px}.Filters-Filters__title--CWfEIOR5{margin-right:10px;font-weight:600}@media (max-width:768px){.Filters-Filters--3oGFmBjg{display:block}.Filters-Filters__title--CWfEIOR5{display:none}.Filters-Filters__clear--poaxMvH8{margin-left:0}.Filters-Filters__clear--poaxMvH8 button{width:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Filters": "Filters-Filters--3oGFmBjg",
	"Filters__clear": "Filters-Filters__clear--poaxMvH8",
	"Filters__title": "Filters-Filters__title--CWfEIOR5"
};
module.exports = ___CSS_LOADER_EXPORT___;
