<template>
  <span v-if="text" :class="rootClasses">
    {{ text }}
  </span>
</template>

<script>
export default {
  name: 'Badge',
  props: {
    text: {
      type: String,
      default: null,
    },
    theme: {
      type: String,
      default: process.env.theme || 'light',
    },
  },
  computed: {
    rootClasses() {
      return {
        [this.$style.Badge]: true,
        [this.$style[`Badge_theme_${this.theme}`]]: this.theme,
      }
    },
  },
}
</script>
<style lang="scss" module>
.Badge {
  display: inline-flex;
  height: 26px;
  min-width: 26px;
  padding: 0 10px;
  white-space: nowrap;
  border: 1px solid;
  align-items: center;
  border-radius: 500px;
  font-size: 13px;
  &_theme {
    &_light {
      background: #fff;
      border-color: $--color-grey-light;
      color: $--text-color;
    }
    &_dark {
      background: $--color-grey-darker;
      border-color: #000;
      color: $--color-grey-light;
    }
  }
}
</style>
