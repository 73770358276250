<template>
  <div :class="rootClasses">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Container',
  props: {
    width: {
      type: String,
      default: null,
    },
  },
  computed: {
    rootClasses() {
      return {
        [this.$style.Container]: true,
        [this.$style[`Container_${this.width}`]]: this.width,
      }
    },
  },
}
</script>
<style lang="scss" module>
.Container {
  padding-left: 10px;
  padding-right: 10px;
  max-width: $--container-width;
  margin: 0 auto;
  &_small {
    max-width: $--container-width-sm;
  }
  &_medium {
    max-width: $--container-width-md;
  }
}
</style>
