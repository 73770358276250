import Vue from 'vue'
import _get from 'lodash.get'
import siteContent from './../static/site-content.json'

Vue.prototype.$siteContent = key => {
  const content = siteContent[process.env.AR_SITE_ID]
  const defaultContent = siteContent.defaults
  const val = _get(content, key, '') || _get(defaultContent, key, '')
  if (!val) console.warn(key, ' is empty in $siteContent')
  return val || ''
}
