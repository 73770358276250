// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModelsList-ModelsList--rgDeVdgJ{display:flex;margin-left:-10px;margin-right:-10px;flex-wrap:wrap}.ModelsList-ModelsList__el--1F0bJcas{padding:0 10px;flex:1 1 25%;width:25%;margin-bottom:20px}@media (max-width:991px){.ModelsList-ModelsList__el--1F0bJcas{flex:1 1 50%;width:50%}}@media (max-width:767px){.ModelsList-ModelsList__el--1F0bJcas{flex:1 1 auto;width:100%}.ModelsList-ModelsList__el--1F0bJcas+.ModelsList-ModelsList__el--1F0bJcas{margin-top:20px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ModelsList": "ModelsList-ModelsList--rgDeVdgJ",
	"ModelsList__el": "ModelsList-ModelsList__el--1F0bJcas"
};
module.exports = ___CSS_LOADER_EXPORT___;
