// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CtaBanner-CtaBanner--14YLvzjq{display:flex;flex-direction:column;background-size:cover;background-position:50%}.CtaBanner-CtaBanner__content--1bs1ZWz5{padding:30px;order:1!important}.CtaBanner-CtaBanner__img--3tWAtgYp{text-align:center;order:3;line-height:0}.CtaBanner-CtaBanner__img--3tWAtgYp img{max-width:100%}.CtaBanner-CtaBanner_theme_light--3lu6My0v{background-color:#f6f8fa}.CtaBanner-CtaBanner_theme_light--3lu6My0v .CtaBanner-CtaBanner__content--1bs1ZWz5{background:rgba(246,248,250,.7)}.CtaBanner-CtaBanner_theme_dark--24l28dLn{background-color:#333;color:#fff}.CtaBanner-CtaBanner_theme_dark--24l28dLn .CtaBanner-CtaBanner__content--1bs1ZWz5{background:rgba(51,51,51,.7)}@media (min-width:992px){.CtaBanner-CtaBanner--14YLvzjq{flex-direction:row;min-height:480px}.CtaBanner-CtaBanner__content--1bs1ZWz5{display:flex;align-items:center;padding:50px 70px;width:60%}.CtaBanner-CtaBanner__content_left--38PojnVf{order:1}.CtaBanner-CtaBanner__content_right--2TtUBCqL{order:3}.CtaBanner-CtaBanner__img--3tWAtgYp{position:relative;width:40%}.CtaBanner-CtaBanner__img--3tWAtgYp img{position:absolute;left:50%;margin-left:-200px;bottom:0;line-height:0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CtaBanner": "CtaBanner-CtaBanner--14YLvzjq",
	"CtaBanner__content": "CtaBanner-CtaBanner__content--1bs1ZWz5",
	"CtaBanner__img": "CtaBanner-CtaBanner__img--3tWAtgYp",
	"CtaBanner_theme_light": "CtaBanner-CtaBanner_theme_light--3lu6My0v",
	"CtaBanner_theme_dark": "CtaBanner-CtaBanner_theme_dark--24l28dLn",
	"CtaBanner__content_left": "CtaBanner-CtaBanner__content_left--38PojnVf",
	"CtaBanner__content_right": "CtaBanner-CtaBanner__content_right--2TtUBCqL"
};
module.exports = ___CSS_LOADER_EXPORT___;
