<template>
  <div v-if="banners" :class="rootClasses">
    <a v-for="(item, i) in banners" :key="i" :href="item.link" :class="$style['BoxBanners__item']" :style="{ backgroundImage: `url(${item.img})` }">
      <span :class="$style['BoxBanners__content']">
        <span :class="$style['BoxBanners__title']">
          {{ item.title }}
        </span>
        <span :class="$style['BoxBanners__subtitle']">
          {{ item.subtitle }}
        </span>
      </span>
    </a>
  </div>
</template>

<script>
export default {
  name: 'BoxBanners',
  props: {
    banners: {
      type: Array,
      default: null,
    },
    theme: {
      type: String,
      default: process.env.theme || 'light',
    },
  },
  computed: {
    rootClasses() {
      return {
        [this.$style.BoxBanners]: true,
        [this.$style[`BoxBanners_theme_${this.theme}`]]: this.theme,
      }
    },
  },
}
</script>
<style lang="scss" module>
.BoxBanners {
  $c: &;
  display: flex;
  &__item {
    flex: 1 1 0%;
    height: 300px;
    background-size: cover;
    background-position: center center;
    text-decoration: none !important;
    position: relative;
    &:hover {
      #{$c}__content {
        height: 160px;
      }
    }
    @media (max-width: $--sm - 1) {
      margin: 30px 0;
    }
  }
  &__content {
    display: flex;
    height: 140px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    font-size: 22px;
    font-weight: $--fw-medium;
    transition-duration: 0.2s;
  }
  &__title {
    display: block;
    margin-bottom: 10px;
  }
  &__subtitle {
    display: block;
    text-transform: uppercase;
    color: $--box-banners-subtitle;
  }
  &_theme {
    &_light {
      #{$c}__content {
        background: rgba(#ffffff, 0.8);
      }
      #{$c}__title {
        color: $--text-color;
      }
    }
    &_dark {
      #{$c}__content {
        background: rgba(#000000, 0.7);
      }
      #{$c}__title {
        color: #ffffff;
      }
    }
  }
  @media (max-width: $--sm - 1) {
    display: block;
    &__item {
      display: block;
      height: 220px;
    }
  }
}
</style>
