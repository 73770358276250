<template lang="html">
  <span :class="rootClasses">
    <span v-if="!!$slots.left" :class="$style['PlainText__left']">
      <slot name="left" />
    </span>
    <span :class="$style['PlainText__content']">
      <slot />
    </span>
    <span v-if="!!$slots.right" :class="$style['PlainText__right']">
      <slot name="right" />
    </span>
  </span>
</template>
<script>
export default {
  name: 'PlainText',
  props: {
    weight: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
    uppercase: {
      type: Boolean,
      default: null,
    },
    center: {
      type: Boolean,
      default: null,
    },
    right: {
      type: Boolean,
      default: null,
    },
    nowrap: {
      type: Boolean,
      default: null,
    },
    inline: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    rootClasses() {
      return {
        [this.$style.PlainText]: true,
        [this.$style.PlainText_uppercase]: this.uppercase,
        [this.$style.PlainText_center]: this.center,
        [this.$style.PlainText_right]: this.right,
        [this.$style.PlainText_nowrap]: this.nowrap,
        [this.$style.PlainText_flex]: !!this.$slots.left,
        [this.$style.PlainText_inline]: this.inline,
        [this.$style[`PlainText_weight_${this.weight}`]]: this.weight,
        [this.$style[`PlainText_size_${this.size}`]]: this.size,
        [this.$style[`PlainText_color_${this.color}`]]: this.color,
      }
    },
  },
}
</script>

<style lang="scss" module>
.PlainText {
  display: block;
  &__left {
    flex-shrink: 0;
    margin-right: 7px;
  }
  &__right {
    flex-shrink: 0;
    margin-left: 7px;
  }
  &__content {
    flex: 1 1 auto;
  }
  &_weight {
    &_medium {
      font-weight: $--fw-medium;
    }
    &_bold {
      font-weight: $--fw-bold;
    }
  }
  &_size {
    &_small {
      font-size: 14px;
    }
    &_smaller {
      font-size: 13px;
    }
    &_medium {
      font-size: 18px;
    }
    &_large {
      font-size: 22px;
    }
    &_larger {
      font-size: 26px;
    }
    &_largest {
      font-size: 30px;
    }
  }
  &_color {
    &_grey {
      color: $--color-grey;
    }
    &_red {
      color: $--color-danger;
    }
    &_green {
      color: $--color-success;
    }
    &_orange {
      color: $--color-warning;
    }
    &_primary {
      color: $--color-primary;
    }
  }
  &_uppercase {
    text-transform: uppercase;
  }
  &_center {
    text-align: center;
  }
  &_right {
    text-align: right;
  }
  &_nowrap {
    white-space: nowrap;
  }
  &_flex {
    display: flex;
  }
  &_inline {
    display: inline;
  }
}
</style>
