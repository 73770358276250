// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ContentSectionTitle-ContentSectionTitle--7qo_SUXG{display:inline-block;font-size:24px;text-transform:uppercase;font-weight:600;margin:0;padding:0}.ContentSectionTitle-ContentSectionTitle_theme_light--3ZaF0Acd{color:#444}.ContentSectionTitle-ContentSectionTitle_theme_dark--3fNogrlB{color:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ContentSectionTitle": "ContentSectionTitle-ContentSectionTitle--7qo_SUXG",
	"ContentSectionTitle_theme_light": "ContentSectionTitle-ContentSectionTitle_theme_light--3ZaF0Acd",
	"ContentSectionTitle_theme_dark": "ContentSectionTitle-ContentSectionTitle_theme_dark--3fNogrlB"
};
module.exports = ___CSS_LOADER_EXPORT___;
