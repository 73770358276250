<template>
  <div :class="rootClasses">
    <Container>
      <div :class="$style['FooterMenu__content']">
        <nuxt-link v-for="item in menu" :key="item.key" :to="`/${item.key}`">
          {{ item.title }}
        </nuxt-link>
      </div>
    </Container>
  </div>
</template>

<script>
import Container from '../Container'
export default {
  name: 'FooterMenu',
  components: {
    Container,
  },
  props: {
    menu: {
      type: Array,
      default: null,
    },
    theme: {
      type: String,
      default: process.env.theme || 'light',
    },
  },
  computed: {
    rootClasses() {
      return {
        [this.$style.FooterMenu]: true,
        [this.$style[`FooterMenu_theme_${this.theme}`]]: this.theme,
      }
    },
  },
}
</script>
<style lang="scss" module>
.FooterMenu {
  font-weight: $--fw-medium;
  box-shadow: 0 -10px 10px rgba(#000000, 0.05);
  position: relative;
  z-index: 2;
  &__content {
    display: flex;
    height: 80px;
    align-items: center;
  }
  a {
    margin-right: 20px;
    font-size: 14px;
    text-transform: uppercase;
    transition-duration: 0.2s;
  }
  &_theme {
    &_light {
      background: #fff;
      a {
        color: $--color-grey;
        text-decoration: none;
        &:hover {
          color: $--color-primary;
        }
      }
    }
    &_dark {
      background: $--color-grey-darkest;
      a {
        color: $--color-grey;
        text-decoration: none;
        &:hover {
          color: #fff;
        }
      }
    }
  }
  @media (max-width: $--sm) {
    display: none;
  }
}
</style>
