// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FooterBanner-FooterBanner--2e4ZxWA_{display:block;padding-top:40px}.FooterBanner-FooterBanner__img--1siLaouk{flex-shrink:0;width:320px;margin-right:30px}.FooterBanner-FooterBanner__img--1siLaouk img{position:absolute;bottom:0;left:0}.FooterBanner-FooterBanner__content--3buqmi4k{flex:1 1 auto}.FooterBanner-FooterBanner__container--Rx24M8I2{position:relative;display:flex;align-items:center;min-height:400px}.FooterBanner-FooterBanner__title--bebpJHrj{font-size:30px;font-weight:700;margin-bottom:30px}.FooterBanner-FooterBanner__text--2DoMbNXT{margin-bottom:30px;line-height:24px}.FooterBanner-FooterBanner__text--2DoMbNXT p+p{margin-top:15px}@media (max-width:768px){.FooterBanner-FooterBanner--2e4ZxWA_{display:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FooterBanner": "FooterBanner-FooterBanner--2e4ZxWA_",
	"FooterBanner__img": "FooterBanner-FooterBanner__img--1siLaouk",
	"FooterBanner__content": "FooterBanner-FooterBanner__content--3buqmi4k",
	"FooterBanner__container": "FooterBanner-FooterBanner__container--Rx24M8I2",
	"FooterBanner__title": "FooterBanner-FooterBanner__title--bebpJHrj",
	"FooterBanner__text": "FooterBanner-FooterBanner__text--2DoMbNXT"
};
module.exports = ___CSS_LOADER_EXPORT___;
