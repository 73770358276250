// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ContentSection-ContentSection--xK2dPSQW{position:relative}.ContentSection-ContentSection__main--1-wLStrP{display:flex}.ContentSection-ContentSection__in--3wMLEsoq{flex:1 1 auto}.ContentSection-ContentSection_size_small--10qfIx-n{padding-top:15px;padding-bottom:15px}.ContentSection-ContentSection_size_medium--1uOk5LCM{padding-top:30px;padding-bottom:30px}.ContentSection-ContentSection_size_large--1SJL428E{padding-top:45px;padding-bottom:45px}.ContentSection-ContentSection_theme_light--3BLH6Qrh{background:transparent}.ContentSection-ContentSection_theme_dark--2imBesu_{background:#212121;color:#fff}@media (max-width:767px){.ContentSection-ContentSection__main--1-wLStrP{display:block}.ContentSection-ContentSection_size_medium--1uOk5LCM{padding-top:20px;padding-bottom:20px}.ContentSection-ContentSection_size_large--1SJL428E{padding-top:30px;padding-bottom:30px}}@media (min-width:768px){.ContentSection-ContentSection__left--wPp8t4fp{flex:0 0 300px;max-width:300px;padding-right:30px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ContentSection": "ContentSection-ContentSection--xK2dPSQW",
	"ContentSection__main": "ContentSection-ContentSection__main--1-wLStrP",
	"ContentSection__in": "ContentSection-ContentSection__in--3wMLEsoq",
	"ContentSection_size_small": "ContentSection-ContentSection_size_small--10qfIx-n",
	"ContentSection_size_medium": "ContentSection-ContentSection_size_medium--1uOk5LCM",
	"ContentSection_size_large": "ContentSection-ContentSection_size_large--1SJL428E",
	"ContentSection_theme_light": "ContentSection-ContentSection_theme_light--3BLH6Qrh",
	"ContentSection_theme_dark": "ContentSection-ContentSection_theme_dark--2imBesu_",
	"ContentSection__left": "ContentSection-ContentSection__left--wPp8t4fp"
};
module.exports = ___CSS_LOADER_EXPORT___;
