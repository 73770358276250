// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Button-Button--2Oea5Jv_{text-transform:uppercase}.Button-Button_width_small--JMlSGUgn{min-width:90px}.Button-Button_width_medium--2hT4cnkW{min-width:150px}.Button-Button_width_large--2GKf2Ywm{min-width:180px}.Button-Button_width_full--EA3Ds1dl{width:100%}@media (max-width:767px){.Button-Button_width_full-mobile--3tNtdL3u{width:100%}}.Button-Button_size_large--1oAGmRFF{height:48px}.Button-Button_size_mini--3JYzs5Wi{height:28px;padding:7px 15px!important;font-size:12px;text-transform:none}@media (max-width:767px){.Button-Button_size_mini--3JYzs5Wi{margin:0 15px 15px 0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Button": "Button-Button--2Oea5Jv_",
	"Button_width_small": "Button-Button_width_small--JMlSGUgn",
	"Button_width_medium": "Button-Button_width_medium--2hT4cnkW",
	"Button_width_large": "Button-Button_width_large--2GKf2Ywm",
	"Button_width_full": "Button-Button_width_full--EA3Ds1dl",
	"Button_width_full-mobile": "Button-Button_width_full-mobile--3tNtdL3u",
	"Button_size_large": "Button-Button_size_large--1oAGmRFF",
	"Button_size_mini": "Button-Button_size_mini--3JYzs5Wi"
};
module.exports = ___CSS_LOADER_EXPORT___;
