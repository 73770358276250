// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CategoriesList-CategoriesList--2TyV6ePC{display:flex;margin-left:-10px;margin-right:-10px;flex-wrap:wrap}.CategoriesList-CategoriesList__el--25HhFgM0{padding:0 10px;flex:0 1 25%;width:25%;margin-bottom:20px}@media (max-width:991px){.CategoriesList-CategoriesList__el--25HhFgM0{flex:1 1 50%;width:50%}}@media (max-width:767px){.CategoriesList-CategoriesList__el--25HhFgM0{flex:1 1 auto;width:100%}.CategoriesList-CategoriesList__el--25HhFgM0+.CategoriesList-CategoriesList__el--25HhFgM0{margin-top:20px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CategoriesList": "CategoriesList-CategoriesList--2TyV6ePC",
	"CategoriesList__el": "CategoriesList-CategoriesList__el--25HhFgM0"
};
module.exports = ___CSS_LOADER_EXPORT___;
