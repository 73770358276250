// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FiltersDropdown-FiltersDropdown--SVtWCQej{position:absolute;z-index:999;top:100%;width:100%;right:0}.FiltersDropdown-FiltersDropdown__main--2NbXTgUM{max-height:300px;overflow-y:auto}.FiltersDropdown-FiltersDropdown__content--2NlVzk4g{position:relative;z-index:999;background:#fff;color:#444;padding:20px;box-shadow:5px 5px 20px rgba(0,0,0,.4);margin-top:10px;border-radius:5px;transform:translateY(15px);opacity:0;transition-duration:.3s}.FiltersDropdown-FiltersDropdown__content_active--3VzCArq-{opacity:1;transform:translateY(0)}.FiltersDropdown-FiltersDropdown__backdrop--D2CzGxzq{position:fixed;z-index:998;top:0;left:0;right:0;bottom:0;background:rgba(0,0,0,.7)}.FiltersDropdown-FiltersDropdown__title--g0pHElHC{margin-bottom:15px;font-size:18px}.FiltersDropdown-FiltersDropdown__actions--39V5M_I4{margin-top:20px}@media (min-width:768px){.FiltersDropdown-FiltersDropdown--SVtWCQej{min-width:600px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FiltersDropdown": "FiltersDropdown-FiltersDropdown--SVtWCQej",
	"FiltersDropdown__main": "FiltersDropdown-FiltersDropdown__main--2NbXTgUM",
	"FiltersDropdown__content": "FiltersDropdown-FiltersDropdown__content--2NlVzk4g",
	"FiltersDropdown__content_active": "FiltersDropdown-FiltersDropdown__content_active--3VzCArq-",
	"FiltersDropdown__backdrop": "FiltersDropdown-FiltersDropdown__backdrop--D2CzGxzq",
	"FiltersDropdown__title": "FiltersDropdown-FiltersDropdown__title--g0pHElHC",
	"FiltersDropdown__actions": "FiltersDropdown-FiltersDropdown__actions--39V5M_I4"
};
module.exports = ___CSS_LOADER_EXPORT___;
