// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Footer-Footer--NzIQZbed{display:block}.Footer-Footer__content--oyVRbuSf{padding:50px 0;font-size:14px}.Footer-Footer__content--oyVRbuSf p+p{margin-top:25px}.Footer-Footer__content--oyVRbuSf a{color:#777;text-decoration:underline}.Footer-Footer__content--oyVRbuSf a:hover{text-decoration:none}.Footer-Footer_theme_light--2RmTpC1D{background:#f6f8fa;color:#444}.Footer-Footer_theme_light--2RmTpC1D .Footer-Footer__content--oyVRbuSf{color:#777}.Footer-Footer_theme_dark--18W6daEK{background:#333;color:#d8d8d8}.Footer-Footer_theme_dark--18W6daEK .Footer-Footer__content--oyVRbuSf,.Footer-Footer_theme_dark--18W6daEK .Footer-Footer__content--oyVRbuSf a{color:#d8d8d8}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Footer": "Footer-Footer--NzIQZbed",
	"Footer__content": "Footer-Footer__content--oyVRbuSf",
	"Footer_theme_light": "Footer-Footer_theme_light--2RmTpC1D",
	"Footer_theme_dark": "Footer-Footer_theme_dark--18W6daEK"
};
module.exports = ___CSS_LOADER_EXPORT___;
