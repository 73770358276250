// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FiltersColumnsItem-FiltersColumnsItem--dx13LL2P{flex:1 1 0%;min-width:33.333333%;padding:10px}@media (min-width:768px){.FiltersColumnsItem-FiltersColumnsItem--dx13LL2P{max-width:33.333333%}}.FiltersColumnsItem-FiltersColumnsItem__title--1vUiwVXo{font-weight:600;margin-bottom:15px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FiltersColumnsItem": "FiltersColumnsItem-FiltersColumnsItem--dx13LL2P",
	"FiltersColumnsItem__title": "FiltersColumnsItem-FiltersColumnsItem__title--1vUiwVXo"
};
module.exports = ___CSS_LOADER_EXPORT___;
