<template>
  <div :class="rootClasses">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Block',
  props: {
    top: {
      type: String,
      default: null,
    },
    bottom: {
      type: String,
      default: null,
    },
    left: {
      type: String,
      default: null,
    },
    right: {
      type: String,
      default: null,
    },
  },
  computed: {
    rootClasses() {
      return {
        [this.$style.Block]: true,
        [this.$style[`Block_top_${this.top}`]]: this.top,
        [this.$style[`Block_bottom_${this.bottom}`]]: this.bottom,
        [this.$style[`Block_left_${this.left}`]]: this.left,
        [this.$style[`Block_right_${this.right}`]]: this.right,
      }
    },
  },
}
</script>

<style lang="scss" module>
.Block {
  $small: 5px;
  $medium: 15px;
  $large: 30px;
  display: block;
  &_top {
    &_small {
      margin-top: $small;
    }
    &_medium {
      margin-top: $medium;
    }
    &_large {
      margin-top: $large;
    }
  }
  &_bottom {
    &_small {
      margin-bottom: $small;
    }
    &_medium {
      margin-bottom: $medium;
    }
    &_large {
      margin-bottom: $large;
    }
  }
  &_left {
    &_small {
      margin-left: $small;
    }
    &_medium {
      margin-left: $medium;
    }
    &_large {
      margin-left: $large;
    }
  }
  &_right {
    &_small {
      margin-right: $small;
    }
    &_medium {
      margin-right: $medium;
    }
    &_large {
      margin-right: $large;
    }
  }
}
</style>
