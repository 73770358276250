/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M16 0C7.178 0 0 7.178 0 16c0 8.823 7.178 16.001 16 16.001s16-7.178 16-16C32 7.178 24.823 0 16 0zm9.226 24.82v-.632c0-2.182-2.521-2.978-3.871-3.555-.488-.209-1.41-.652-2.354-1.114-.32-.156-.607-.572-.643-.927l-.104-1.034c.723-.673 1.374-1.61 1.485-2.656h.163c.256 0 .498-.192.536-.427l.256-1.581c.034-.338-.285-.543-.544-.543a5.586 5.586 0 00.082-.648c.016-.144.03-.293.036-.447.027-.746-.099-1.4-.308-1.765a4.185 4.185 0 00-.984-1.432c-1.25-1.184-2.699-1.644-3.935-.693-.842-.146-1.842.239-2.564 1.085-.395.462-.64.99-.745 1.51a4.791 4.791 0 00-.173 1.142c-.016.473.03.905.117 1.255a.529.529 0 00-.492.537l.255 1.58c.038.236.28.428.536.428h.146a5.306 5.306 0 001.498 2.706l-.1 1c-.037.354-.324.768-.644.926-.914.446-1.814.875-2.353 1.098-1.27.523-3.87 1.373-3.87 3.555v.494c-2.12-2.282-3.427-5.327-3.427-8.68 0-7.045 5.732-12.777 12.778-12.777S28.78 8.957 28.78 16.003a12.738 12.738 0 01-3.554 8.816z" _fill="#777" fill-rule="nonzero"/>'
  }
})
