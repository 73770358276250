<template lang="html">
  <svgicon :name="`${name}`" :class="rootClasses" :color="customColor" />
</template>
<script>
import svgicon from 'vue-svgicon'
import '../../ico'
export default {
  name: 'Icon',
  components: {
    svgicon,
  },
  props: {
    name: {
      type: String,
      default: null,
    },
    /**
     * Icon size
     * `smaller, small, medium, large`
     */
    size: {
      type: String,
      default: null,
    },
    /**
     * Icon color
     * `grey, grey-light, white, primary`
     */
    color: {
      type: String,
      default: null,
    },
    customColor: {
      type: String,
      default: null,
    },
  },
  computed: {
    rootClasses() {
      return {
        [this.$style.Icon]: true,
        [this.$style[`Icon_${this.size}`]]: this.size,
        [this.$style[`Icon_${this.color}`]]: this.color,
      }
    },
  },
}
</script>

<style lang="scss" module>
.Icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  vertical-align: middle;
  fill: $--color-grey-darkest;
  &_smaller {
    width: 10px;
    height: 10px;
  }
  &_small {
    width: 14px;
    height: 14px;
  }
  &_medium {
    width: 24px;
    height: 24px;
  }
  &_large {
    width: 30px;
    height: 30px;
  }
  &_grey {
    fill: $--color-grey;
  }
  &_grey-light {
    fill: $--color-grey-light;
  }
  &_white {
    fill: #fff;
  }
  &_primary {
    fill: $--color-primary;
  }
}
</style>
