// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Rate-Rate--puHKtsa6,.Rate-Rate--puHKtsa6 button{display:flex;align-items:center}.Rate-Rate--puHKtsa6 button{cursor:pointer;background-color:transparent;width:40px;height:40px;border-radius:50%;justify-content:center;outline:none;margin-right:5px}.Rate-Rate--puHKtsa6 button:active{box-shadow:inset 0 1px 3px rgba(0,0,0,.2)}.Rate-Rate--puHKtsa6 button svg{width:16px;height:16px}.Rate-Rate__like--32JpgbKd{border:1px solid #67c23a}.Rate-Rate__like--32JpgbKd svg{fill:#67c23a}.Rate-Rate__like--32JpgbKd:hover,.Rate-Rate__like_active--373rm46b{background:#67c23a!important;border-color:#67c23a}.Rate-Rate__like--32JpgbKd:hover svg,.Rate-Rate__like_active--373rm46b svg{fill:#fff}.Rate-Rate__dislike--2eqCt7ln{border:1px solid #d8d8d8}.Rate-Rate__dislike--2eqCt7ln svg{fill:#777}.Rate-Rate__dislike--2eqCt7ln:hover{border-color:#f56c6c}.Rate-Rate__dislike--2eqCt7ln:hover svg{fill:#f56c6c}.Rate-Rate__dislike_active--8sQebUUF{background:#f56c6c!important;border-color:#f56c6c}.Rate-Rate__dislike_active--8sQebUUF svg{fill:#fff!important}.Rate-Rate__score--2Jm08VW1{color:#777;margin-left:10px;font-size:14px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Rate": "Rate-Rate--puHKtsa6",
	"Rate__like": "Rate-Rate__like--32JpgbKd",
	"Rate__like_active": "Rate-Rate__like_active--373rm46b",
	"Rate__dislike": "Rate-Rate__dislike--2eqCt7ln",
	"Rate__dislike_active": "Rate-Rate__dislike_active--8sQebUUF",
	"Rate__score": "Rate-Rate__score--2Jm08VW1"
};
module.exports = ___CSS_LOADER_EXPORT___;
