<template>
  <div :class="$style['FiltersColumnsItem']">
    <div v-if="title" :class="$style['FiltersColumnsItem__title']">
      {{ title }}
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FiltersColumnsItem',
  props: {
    title: {
      type: String,
      default: null,
    },
  },
}
</script>
<style lang="scss" module>
.FiltersColumnsItem {
  flex: 1 1 0%;
  min-width: 33.333333%;
  @media (min-width: $--sm) {
    max-width: 33.333333%;
  }
  padding: 10px;
  &__title {
    font-weight: $--fw-medium;
    margin-bottom: 15px;
  }
}
</style>
