<template>
  <div :class="$style['CtaBannerFooter']">
    <div :class="$style['CtaBannerFooter__button']">
      <slot />
    </div>
    <div :class="$style['CtaBannerFooter__text']">
      <slot name="text" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'CtaBannerFooter',
}
</script>
<style lang="scss" module>
.CtaBannerFooter {
  align-items: center;
  &__button {
    flex-shrink: 0;
    margin-right: 20px;
  }
  &__text {
    flex: 1 1 auto;
    font-size: 14px;
  }
  @media (min-width: $--sm) {
    display: flex;
  }
  @media (max-width: $--sm - 1) {
    text-align: center;
    &__button button {
      width: 100%;
    }
    &__text {
      margin-top: 15px;
    }
  }
}
</style>
