// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ListDefinition-ListDefinition__title--Nm8sFb-q{font-size:14px;font-weight:700;padding-bottom:5px}.ListDefinition-ListDefinition--2BzHA-Qy+.ListDefinition-ListDefinition--2BzHA-Qy{margin-top:15px}.ListDefinition-ListDefinition_block--L0dHrSdr .ListDefinition-ListDefinition__title--Nm8sFb-q{padding-bottom:2px}@media (min-width:768px){.ListDefinition-ListDefinition--2BzHA-Qy:not(.ListDefinition-ListDefinition_block--L0dHrSdr){display:flex}.ListDefinition-ListDefinition--2BzHA-Qy:not(.ListDefinition-ListDefinition_block--L0dHrSdr) .ListDefinition-ListDefinition__title--Nm8sFb-q{width:150px;flex-shrink:0;padding-top:3px;padding-bottom:0}.ListDefinition-ListDefinition--2BzHA-Qy:not(.ListDefinition-ListDefinition_block--L0dHrSdr) .ListDefinition-ListDefinition__main--1rBRCQSu{float:1 1 auto}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ListDefinition__title": "ListDefinition-ListDefinition__title--Nm8sFb-q",
	"ListDefinition": "ListDefinition-ListDefinition--2BzHA-Qy",
	"ListDefinition_block": "ListDefinition-ListDefinition_block--L0dHrSdr",
	"ListDefinition__main": "ListDefinition-ListDefinition__main--1rBRCQSu"
};
module.exports = ___CSS_LOADER_EXPORT___;
