// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeaderMenu-HeaderMenu__content--2-5cF7KZ{display:flex}.HeaderMenu-HeaderMenu__item--1nQsjQ0k{display:flex;height:30px;align-items:center;padding:5px 15px;border-radius:500px;transition-duration:.2s;font-weight:600;text-decoration:none!important}.HeaderMenu-HeaderMenu__item--1nQsjQ0k:hover,.HeaderMenu-HeaderMenu__item_active--2TlbADAT{color:#f6ee4b!important}.HeaderMenu-HeaderMenu_theme_light--2sFUAotk .HeaderMenu-HeaderMenu__item--1nQsjQ0k{color:#777}.HeaderMenu-HeaderMenu_theme_light--2sFUAotk .HeaderMenu-HeaderMenu__item--1nQsjQ0k.HeaderMenu-HeaderMenu__item_active--2TlbADAT{color:#f6ee4b}.HeaderMenu-HeaderMenu_theme_dark--UeFIdvBV .HeaderMenu-HeaderMenu__item--1nQsjQ0k{color:hsla(0,0%,100%,.5)}.HeaderMenu-HeaderMenu_theme_dark--UeFIdvBV .HeaderMenu-HeaderMenu__item--1nQsjQ0k.HeaderMenu-HeaderMenu__item_active--2TlbADAT{background:hsla(0,0%,84.7%,.1)}@media (max-width:767px){.HeaderMenu-HeaderMenu--3r6jSui1{position:fixed;right:0;z-index:9999;opacity:0;visibility:hidden}.HeaderMenu-HeaderMenu--3r6jSui1,.HeaderMenu-HeaderMenu__content--2-5cF7KZ{top:0;left:0;bottom:0;transition-duration:.3s}.HeaderMenu-HeaderMenu__content--2-5cF7KZ{position:absolute;z-index:2;display:block;width:260px;padding:10px 0;box-shadow:10px 0 30px rgba(0,0,0,.2);transform:translate(-260px)}.HeaderMenu-HeaderMenu__footer--1-scIbGW{padding:15px}.HeaderMenu-HeaderMenu_active--1IU5dfSo{opacity:1;visibility:visible}.HeaderMenu-HeaderMenu_active--1IU5dfSo .HeaderMenu-HeaderMenu__content--2-5cF7KZ{transform:translate(0)}.HeaderMenu-HeaderMenu_theme_light--2sFUAotk .HeaderMenu-HeaderMenu__content--2-5cF7KZ{background:#fff}.HeaderMenu-HeaderMenu_theme_dark--UeFIdvBV .HeaderMenu-HeaderMenu__content--2-5cF7KZ{background:#212121}.HeaderMenu-HeaderMenu__backdrop--3MaCOq8z{position:absolute;z-index:1;top:0;left:0;bottom:0;right:0;background:rgba(0,0,0,.8)}.HeaderMenu-HeaderMenu__item--1nQsjQ0k{height:48px;border-radius:0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HeaderMenu__content": "HeaderMenu-HeaderMenu__content--2-5cF7KZ",
	"HeaderMenu__item": "HeaderMenu-HeaderMenu__item--1nQsjQ0k",
	"HeaderMenu__item_active": "HeaderMenu-HeaderMenu__item_active--2TlbADAT",
	"HeaderMenu_theme_light": "HeaderMenu-HeaderMenu_theme_light--2sFUAotk",
	"HeaderMenu_theme_dark": "HeaderMenu-HeaderMenu_theme_dark--UeFIdvBV",
	"HeaderMenu": "HeaderMenu-HeaderMenu--3r6jSui1",
	"HeaderMenu__footer": "HeaderMenu-HeaderMenu__footer--1-scIbGW",
	"HeaderMenu_active": "HeaderMenu-HeaderMenu_active--1IU5dfSo",
	"HeaderMenu__backdrop": "HeaderMenu-HeaderMenu__backdrop--3MaCOq8z"
};
module.exports = ___CSS_LOADER_EXPORT___;
