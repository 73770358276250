<template>
  <div class="err-wrap">
    <div class="err-content">
      <div class="err-content-in">
        <div class="err-title">
          <h1 class="err-title">
            {{ `Welcome to ${$siteContent('globals.websiteName')}!` }}
          </h1>
        </div>
        <div class="err-text">
          {{ $siteContent('components.ContentBanner2.text') }}
        </div>
        <Button color="primary" width="large" size="large" @click="$router.push('/')">
          START THE TOUR
        </Button>
      </div>
    </div>
    <div class="err-img lazyload" :data-bg="imageUrl('error-page.jpg')" />
  </div>
</template>

<script>
import { Button } from 'UI'
export default {
  name: 'Error',
  components: {
    Button,
  },
}
</script>
<style lang="scss">
.err-wrap {
  display: flex;
  flex-grow: 1;
  @media screen and (min-width: $--sm) {
    margin-bottom: -80px;
    min-height: 600px;
  }
}
.err-content {
  display: flex;
  flex: 1 1 auto;
  padding: 30px;
  align-items: center;
  @media screen and (min-width: $--sm) {
    padding: 30px 90px;
    padding-bottom: 110px;
  }
}
.err-content-in {
  flex-grow: 1;
}
.err-img {
  width: 570px;
  flex: 1 0 570px;
  background-size: cover;
  background-position: top center;
  @media screen and (max-width: $--sm - 1) {
    display: none;
  }
}
.err-title {
  font-size: 36px;
  font-weight: $--fw-bold;
  @media screen and (max-width: $--sm - 1) {
    font-size: 28px;
  }
}
.err-text {
  font-size: 18px;
  margin-bottom: 20px;
  @media screen and (max-width: $--sm - 1) {
    font-size: 16px;
  }
}
</style>
