import Vue from 'vue'

import 'element-ui/packages/theme-chalk/src/base.scss'
import 'element-ui/packages/theme-chalk/src/form.scss'
import 'element-ui/packages/theme-chalk/src/form-item.scss'
import 'element-ui/packages/theme-chalk/src/input.scss'
import 'element-ui/packages/theme-chalk/src/button.scss'
import 'element-ui/packages/theme-chalk/src/button-group.scss'
import 'element-ui/packages/theme-chalk/src/dialog.scss'
import 'element-ui/packages/theme-chalk/src/badge.scss'
import 'element-ui/packages/theme-chalk/src/dropdown.scss'
import 'element-ui/packages/theme-chalk/src/dropdown-menu.scss'
import 'element-ui/packages/theme-chalk/src/dropdown-item.scss'
import 'element-ui/packages/theme-chalk/src/pagination.scss'
import 'element-ui/packages/theme-chalk/src/checkbox.scss'
import 'element-ui/packages/theme-chalk/src/checkbox-group.scss'
import 'element-ui/packages/theme-chalk/src/alert.scss'
import 'element-ui/packages/theme-chalk/src/loading.scss'
import 'element-ui/packages/theme-chalk/src/notification.scss'

import Form from 'element-ui/lib/form'
import FormItem from 'element-ui/lib/form-item'
import Input from 'element-ui/lib/input'
import Button from 'element-ui/lib/button'
import ButtonGroup from 'element-ui/lib/button-group'
import Dialog from 'element-ui/lib/dialog'
import Badge from 'element-ui/lib/badge'
import Dropdown from 'element-ui/lib/dropdown'
import DropdownMenu from 'element-ui/lib/dropdown-menu'
import DropdownItem from 'element-ui/lib/dropdown-item'
import Pagination from 'element-ui/lib/pagination'
import Checkbox from 'element-ui/lib/checkbox'
import CheckboxGroup from 'element-ui/lib/checkbox-group'
import Alert from 'element-ui/lib/alert'
import Loading from 'element-ui/lib/loading'
import Notification from 'element-ui/lib/notification'

import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'

// configure language
locale.use(lang)

Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Button)
Vue.use(ButtonGroup)
Vue.use(Dialog)
Vue.use(Badge)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Pagination)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Alert)

Vue.use(Loading.directive)

Vue.prototype.$loading = Loading.service
Vue.prototype.$notify = Notification
