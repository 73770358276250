/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shield': {
    width: 20,
    height: 24,
    viewBox: '0 0 20 24',
    data: '<path pid="0" d="M19.883 3.75C15.788 3.75 12.651 2.58 10 0 7.346 2.58 4.209 3.75.115 3.75c0 6.717-1.39 16.34 9.884 20.248 11.274-3.908 9.884-13.53 9.884-20.249zM9.18 15.57l-3.293-3.293 1.474-1.474 1.819 1.819 3.456-3.457 1.475 1.474-4.931 4.932z" _fill="#F64976" fill-rule="nonzero"/>'
  }
})
