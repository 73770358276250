// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FiltersButton-FiltersButton--10_9BXxo{position:relative;z-index:1000;margin-left:10px}@media (max-width:768px){.FiltersButton-FiltersButton--10_9BXxo{margin-left:0;margin-bottom:10px}.FiltersButton-FiltersButton--10_9BXxo>div,.FiltersButton-FiltersButton--10_9BXxo button{display:block;width:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FiltersButton": "FiltersButton-FiltersButton--10_9BXxo"
};
module.exports = ___CSS_LOADER_EXPORT___;
