export default {
  data() {
    return {
      websiteUrl: this.$siteContent('globals.websiteUrl'),
      websiteName: this.$siteContent('globals.websiteName'),
      websiteEmail: this.$siteContent('globals.websiteEmail'),
      primaryCategory: this.$siteContent('globals.primaryCategory'),
      siteId: process.env.AR_SITE_ID,
    }
  },
  methods: {
    goToJoinNowUrl() {
      window.location.href = this.signupUrl
    },
    imageUrl(imgPath, useSiteId = process.env.AR_SITE_ID) {
      let path = useSiteId ? `/img/${useSiteId}/${imgPath}` : `/img/${imgPath}`
      if (process.env.CLOUDFRONT_URL) path = process.env.CLOUDFRONT_URL + path
      return path
    },
  },
  computed: {
    signupUrl() {
      let signupUrl = this.$siteContent('globals.signupUrl')
      if (window.localStorage.getItem('natsAffiliate')) {
        signupUrl += '&nats='
        signupUrl += window.localStorage.getItem('natsAffiliate')
      }
      return signupUrl
    },
  },
}
