// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InlineElement-InlineElement--sJD6QRgV{display:inline-flex;margin-right:7px;margin-bottom:7px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"InlineElement": "InlineElement-InlineElement--sJD6QRgV"
};
module.exports = ___CSS_LOADER_EXPORT___;
