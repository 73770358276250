import { client, purgeCache } from '~/apollo'
import CURRENT_USER_QUERY from '~/apollo/queries/currentUser.gql'
import SET_CURRENT_USER_MUTATION from '~/apollo/mutations/actions/setCurrentUser.gql'
import SET_TOKEN_MUTATION from '~/apollo/mutations/actions/setToken.gql'

export const resolvers = {
  Mutation: {
    setToken: (_, { token }, { cache }) => {
      window.localStorage.setItem('app-user-token', JSON.stringify(token))
      return null
    },

    setCurrentUser: (_, { user }, { cache }) => {
      cache.writeQuery({
        query: CURRENT_USER_QUERY,
        data: { currentUser: user },
      })
      window.localStorage.setItem('app-current-user', JSON.stringify(user))

      return null
    },

    logout: async () => {
      const { $router } = window.$nuxt

      await purgeCache()
      $router.push('/')

      return null
    },

    login: async (_, { token, user, canRedirect = true }, { cache }) => {
      const { $router } = window.$nuxt

      // this would be there if the server was returning the token properly
      token.__typename = 'Token'

      await client.mutate({
        mutation: SET_TOKEN_MUTATION,
        variables: { token },
      })

      await client.mutate({
        mutation: SET_CURRENT_USER_MUTATION,
        variables: { user },
      })

      if (!canRedirect) return null

      $router.push('/')

      return null
    },
  },
}
