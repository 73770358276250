// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Badge-Badge--2d2TmTju{display:inline-flex;height:26px;min-width:26px;padding:0 10px;white-space:nowrap;border:1px solid;align-items:center;border-radius:500px;font-size:13px}.Badge-Badge_theme_light--yb8S6OJW{background:#fff;border-color:#d8d8d8;color:#444}.Badge-Badge_theme_dark--251or0eN{background:#333;border-color:#000;color:#d8d8d8}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Badge": "Badge-Badge--2d2TmTju",
	"Badge_theme_light": "Badge-Badge_theme_light--yb8S6OJW",
	"Badge_theme_dark": "Badge-Badge_theme_dark--251or0eN"
};
module.exports = ___CSS_LOADER_EXPORT___;
