<template>
  <div :class="$style['ActionBar']">
    <div :class="$style['ActionBar__main']">
      <slot />
    </div>
    <div v-if="!!$slots.right" :class="$style['ActionBar__right']">
      <slot name="right" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActionBar',
}
</script>
<style lang="scss" module>
.ActionBar {
  @media (min-width: $--sm) {
    display: flex;
    align-items: center;
    &__main {
      display: flex;
      align-items: center;
      flex: 1 1 auto;
    }
    &__right {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      flex-grow: 0;
    }
  }
  @media (max-width: $--sm - 1) {
    &__right {
      margin-top: 15px;
    }
  }
}
</style>
