/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'like': {
    width: 16,
    height: 16,
    viewBox: '0 0 241.66 241.66',
    data: '<path pid="0" d="M208.632 133.579c10.345.472 19.121-7.677 19.574-18.203.453-10.526-6.821-19.989-17.174-20.444l-68.73-8.63s14.323-23.958 14.323-59.455C156.625 3.184 139.72 0 129.778 0c-7.821-.003-9.927 15.151-9.927 15.151h-.016c-1.771 9.717-4.077 18.203-12.09 33.827-8.97 17.512-21.186 15.869-35.448 31.467-2.517 2.747-5.899 7.281-9.195 12.86-.269.295-.52.708-.763 1.289-.294.692-.646 1.172-.956 1.812a101.576 101.576 0 00-1.611 3.059c-8.827 8.827-22.579 7.925-28.435 7.925-11.746 0-17.898 6.825-17.898 17.898l-.004 81.828c0 12.423 5.083 16.613 17.903 16.613h17.898c9.011 0 16.067 5.166 26.848 8.949 14.767 5.116 36.821 8.956 74.811 8.956 6.644 0 27.251.025 27.251.025 6.309 0 11.377-2.882 15.034-6.362 1.392-1.323 2.844-3.245 3.465-6.995.101-.581.209-3.017.193-3.346.477-10.728-6.008-14.612-9.682-15.835.1-.034.034-.126.234-.118l11.663.522c10.353.472 20.572-6.986 20.572-19.669 0-10.517-8.525-17.934-18.844-18.439l6.184.287c10.352.455 19.103-7.694 19.582-18.22.451-10.525-7.57-19.432-17.915-19.905z" fill-rule="evenodd" clip-rule="evenodd"/>'
  }
})
