// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CtaBannerFooter-CtaBannerFooter--8AeBmjpL{align-items:center}.CtaBannerFooter-CtaBannerFooter__button--hg7IwgeP{flex-shrink:0;margin-right:20px}.CtaBannerFooter-CtaBannerFooter__text--2cv2GQP7{flex:1 1 auto;font-size:14px}@media (min-width:768px){.CtaBannerFooter-CtaBannerFooter--8AeBmjpL{display:flex}}@media (max-width:767px){.CtaBannerFooter-CtaBannerFooter--8AeBmjpL{text-align:center}.CtaBannerFooter-CtaBannerFooter__button--hg7IwgeP button{width:100%}.CtaBannerFooter-CtaBannerFooter__text--2cv2GQP7{margin-top:15px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CtaBannerFooter": "CtaBannerFooter-CtaBannerFooter--8AeBmjpL",
	"CtaBannerFooter__button": "CtaBannerFooter-CtaBannerFooter__button--hg7IwgeP",
	"CtaBannerFooter__text": "CtaBannerFooter-CtaBannerFooter__text--2cv2GQP7"
};
module.exports = ___CSS_LOADER_EXPORT___;
