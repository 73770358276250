<template>
  <div :class="$style['ContentSectionText']">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ContentSectionText',
}
</script>
<style lang="scss" module>
.ContentSectionText {
  position: relative;
  h2 {
    font-size: 20px;
    font-weight: $--fw-medium;
    padding: 30px 0 15px 0;
  }
  p {
    margin-bottom: 15px;
  }
}
</style>
