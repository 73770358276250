/* eslint-disable */
require('./agent')
require('./calendar')
require('./dislike')
require('./eye')
require('./like')
require('./medal')
require('./menu')
require('./photo-camera')
require('./play')
require('./refresh')
require('./shield')
require('./user')
require('./video-camera')
