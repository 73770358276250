<template>
  <div :class="rootClasses">
    <slot />
    <Container>
      <div :class="$style['Footer__content']">
        <slot name="content" />
      </div>
    </Container>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  components: {
    Container: () => import('../Container'),
  },
  props: {
    theme: {
      type: String,
      default: process.env.theme || 'light',
    },
  },
  computed: {
    rootClasses() {
      return {
        [this.$style.Footer]: true,
        [this.$style[`Footer_theme_${this.theme}`]]: this.theme,
      }
    },
  },
}
</script>
<style lang="scss" module>
.Footer {
  $c: &;
  display: block;
  &__content {
    padding: 50px 0;
    font-size: 14px;
    p {
      + p {
        margin-top: 25px;
      }
    }
    a {
      color: $--color-grey;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
  &_theme {
    &_light {
      background: $--color-grey-lightest;
      color: $--text-color;
      #{$c}__content {
        color: $--color-grey;
      }
    }
    &_dark {
      background: $--color-grey-darker;
      color: $--color-grey-light;
      #{$c}__content {
        color: $--color-grey-light;
        a {
          color: $--color-grey-light;
        }
      }
    }
  }
}
</style>
