/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'agent': {
    width: 20,
    height: 24,
    viewBox: '0 0 20 24',
    data: '<g _fill="#F64976" fill-rule="evenodd"><path pid="0" d="M6.173 20.452l-.005-.011 1.329-1.04-2.467-4.488-.007-.012c-2.985 1.199-5 3.868-5 9.018h7.85l-1.705-3.463.005-.004zM12.254 19.39l1.323 1.05-.005.012.005.004-1.705 3.464h7.836c0-5.15-2.014-7.82-4.998-9.019l-.007.013-2.449 4.475zM9.866 16.591c-.348 0-.69-.023-1.026-.068l.549.992-.437 6.404h1.827l-.436-6.404.549-.992a7.745 7.745 0 01-1.026.068zM16.283 8.904c-.465.065-1.21.155-2.095.237v.966c0 .945-.786 1.712-1.732 1.712-.945 0-1.731-.767-1.731-1.712v-.774a39.04 39.04 0 01-1.772 0v.774c0 .945-.786 1.712-1.731 1.712-.946 0-1.732-.767-1.732-1.712v-.966c-.886-.082-1.6-.172-2.066-.236h.004c.014 3.535 2.899 6.397 6.438 6.397h.002a6.417 6.417 0 006.415-6.398zM3.567 7.62c1.012.14 3.464.434 6.299.434 2.835 0 5.287-.294 6.298-.434l-.001-.01c.471-.07.896-.146 1.267-.23 1.044-.235 1.657-.522 1.657-.83 0-.363-.845-.695-2.243-.95-.22-.04-.454-.078-.7-.114a6.13 6.13 0 00-.174-1.312C15.38 1.77 13.37 0 10.948 0H8.784c-2.47 0-4.57 1.841-5.132 4.318-.086.38-.136.775-.145 1.18-.217.032-.424.066-.62.102-1.397.255-2.243.587-2.243.95 0 .308.613.595 1.657.83.371.084.796.16 1.268.23l-.002.01z"/></g>'
  }
})
