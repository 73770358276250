<template>
  <div :class="$style['FooterBanner']">
    <Container>
      <div :class="$style['FooterBanner__container']">
        <div :class="$style['FooterBanner__img']">
          <img :data-src="img" alt="" class="lazyload">
        </div>
        <div :class="$style['FooterBanner__content']">
          <h2 :class="$style['FooterBanner__title']">
            <slot name="title" />
          </h2>
          <div :class="$style['FooterBanner__text']">
            <slot name="text" />
          </div>
          <slot />
        </div>
      </div>
    </Container>
  </div>
</template>

<script>
import Container from '../Container'
export default {
  name: 'FooterBanner',
  components: {
    Container,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    img: {
      type: String,
      default: null,
    },
  },
}
</script>
<style lang="scss" module>
.FooterBanner {
  $c: &;
  display: block;
  padding-top: 40px;
  &__img {
    flex-shrink: 0;
    width: 320px;
    margin-right: 30px;
    img {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  &__content {
    flex: 1 1 auto;
  }
  &__container {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 400px;
  }
  &__title {
    font-size: 30px;
    font-weight: $--fw-bold;
    margin-bottom: 30px;
  }
  &__text {
    margin-bottom: 30px;
    line-height: 24px;
    p {
      + p {
        margin-top: 15px;
      }
    }
  }
  @media (max-width: $--sm) {
    display: none;
  }
}
</style>
