<template>
  <a :href="href" :class="$style['ImageBanner']">
    <img
      data-sizes="auto"
      :data-src="img"
      alt=""
      :srcset="imgSrcSet"
      sizes="(min-width: 1920px) 1920px, 100vw"
      class="lazyload"
    >
  </a>
</template>

<script>
export default {
  name: 'ImageBanner',
  props: {
    href: {
      type: String,
      default: null,
    },
    img: {
      type: String,
      default: null,
    },
  },
  computed: {
    imgSrcSet() {
      return `${this.img}?tr=w-1920 1920w,
      ${this.img}?tr=w-1600 1600w,
      ${this.img}?tr=w-1024 1024w,
      ${this.img}?tr=w-768 768w,
      ${this.img}?tr=w-640 640w`
    },
  },
}
</script>
<style lang="scss" module>
.ImageBanner {
  display: block;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
</style>
