<template>
  <a :class="$style['JoinBanner']" :href="link">
    {{ text }}
  </a>
</template>

<script>
export default {
  name: 'JoinBanner',
  props: {
    text: {
      type: String,
      default: 'Join Now',
    },
    link: {
      type: String,
      default: '#',
    },
  },
}
</script>

<style lang="scss" module>
.JoinBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  background: $--color-primary;
  color: #fff !important;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  font-size: 24px;
  text-decoration: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-weight: $--fw-medium;
  &:hover {
    background: lighten($--color-primary, 5%);
  }
  @media (max-width: $--sm - 1) {
    font-size: 18px;
    height: auto;
    padding: 20px;
  }
}
</style>
