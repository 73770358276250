<template>
  <div :class="rootClasses">
    <Container :width="width">
      <div :class="$style['ContentSection__main']">
        <div v-if="!!$slots.left" :class="$style['ContentSection__left']">
          <slot name="left" />
        </div>
        <div :class="$style['ContentSection__in']">
          <slot />
        </div>
      </div>
    </Container>
  </div>
</template>

<script>
import Container from '../Container'
export default {
  name: 'ContentSection',
  components: {
    Container,
  },
  props: {
    size: {
      type: String,
      default: 'medium',
    },
    theme: {
      type: String,
      default: process.env.theme || 'light',
    },
    width: {
      type: String,
      default: null,
    },
  },
  computed: {
    rootClasses() {
      return {
        [this.$style.ContentSection]: true,
        [this.$style[`ContentSection_theme_${this.theme}`]]: this.theme,
        [this.$style[`ContentSection_size_${this.size}`]]: this.size,
      }
    },
  },
}
</script>
<style lang="scss" module>
.ContentSection {
  $c: &;
  position: relative;
  &__main {
    display: flex;
  }
  &__in {
    flex: 1 1 auto;
  }
  &_size {
    &_small {
      padding-top: 15px;
      padding-bottom: 15px;
    }
    &_medium {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    &_large {
      padding-top: 45px;
      padding-bottom: 45px;
    }
  }
  &_theme {
    &_light {
      background: transparent;
    }
    &_dark {
      background: $--color-grey-darkest;
      color: #fff;
    }
  }
  @media (max-width: $--sm - 1) {
    &__main {
      display: block;
    }
    &_size {
      &_medium {
        padding-top: 20px;
        padding-bottom: 20px;
      }
      &_large {
        padding-top: 30px;
        padding-bottom: 30px;
      }
    }
  }
  @media (min-width: $--sm) {
    &__left {
      flex: 0 0 300px;
      max-width: 300px;
      padding-right: 30px;
    }
  }
}
</style>
