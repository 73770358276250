<template>
  <div :class="rootClasses">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Box',
  props: {
    size: {
      type: String,
      default: null,
    },
    theme: {
      type: String,
      default: process.env.theme || 'light',
    },
  },
  computed: {
    rootClasses() {
      return {
        [this.$style.Box]: true,
        [this.$style[`Box_size_${this.size}`]]: this.size,
        [this.$style[`Box_theme_${this.theme}`]]: this.theme,
      }
    },
  },
}
</script>

<style lang="scss" module>
.Box {
  display: block;
  border-radius: 3px;
  &_size {
    &_small {
      padding: 5px;
    }
    &_medium {
      padding: 15px;
    }
    &_large {
      padding: 30px;
    }
  }
  &_theme {
    &_light {
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
    }
    &_dark {
      border: 1px solid $--color-grey-darkest;
    }
  }
}
</style>
