// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ContentSectionHead-ContentSectionHead--MsNQ1VyS{display:flex;align-items:center;margin-bottom:15px}.ContentSectionHead-ContentSectionHead__content--3BdxTPSJ{display:flex;flex:1 1 auto;line-height:1}.ContentSectionHead-ContentSectionHead__right--LYSaMUVD{flex-shrink:0}.ContentSectionHead-ContentSectionHead__item--27fg_tan{margin-right:10px}@media (max-width:767px){.ContentSectionHead-ContentSectionHead--MsNQ1VyS{display:block}.ContentSectionHead-ContentSectionHead__right--LYSaMUVD{margin-top:15px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ContentSectionHead": "ContentSectionHead-ContentSectionHead--MsNQ1VyS",
	"ContentSectionHead__content": "ContentSectionHead-ContentSectionHead__content--3BdxTPSJ",
	"ContentSectionHead__right": "ContentSectionHead-ContentSectionHead__right--LYSaMUVD",
	"ContentSectionHead__item": "ContentSectionHead-ContentSectionHead__item--27fg_tan"
};
module.exports = ___CSS_LOADER_EXPORT___;
