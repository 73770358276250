/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'medal': {
    width: 18,
    height: 24,
    viewBox: '0 0 18 24',
    data: '<g _fill="#F64976" fill-rule="evenodd"><path pid="0" d="M8.858 5.061a4.257 4.257 0 00-4.253 4.253 4.257 4.257 0 004.253 4.252 4.257 4.257 0 004.252-4.252A4.257 4.257 0 008.858 5.06z"/><path pid="1" d="M17.716 12.192l-1.29-2.878 1.29-2.878-2.735-1.57-.649-3.087-3.135.337L8.857 0 6.52 2.116l-3.136-.337-.648 3.086L0 6.435l1.29 2.879L0 12.192l2.735 1.57.351 1.672-2.92 6.204 3.048.04L5.186 24l2.87-6.098.802.725.802-.725L12.53 24l1.972-2.322 3.047-.04-2.92-6.204.352-1.672 2.735-1.57zm-8.858 2.78a5.665 5.665 0 01-5.659-5.658 5.665 5.665 0 015.659-5.659 5.665 5.665 0 015.659 5.659 5.665 5.665 0 01-5.66 5.658z" fill-rule="nonzero"/></g>'
  }
})
