// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ContentSectionText-ContentSectionText--1FzBE7Y-{position:relative}.ContentSectionText-ContentSectionText--1FzBE7Y- h2{font-size:20px;font-weight:600;padding:30px 0 15px}.ContentSectionText-ContentSectionText--1FzBE7Y- p{margin-bottom:15px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ContentSectionText": "ContentSectionText-ContentSectionText--1FzBE7Y-"
};
module.exports = ___CSS_LOADER_EXPORT___;
