// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BoxBanners-BoxBanners--3icTObZW{display:flex}.BoxBanners-BoxBanners__item--3w6OGaz4{flex:1 1 0%;height:300px;background-size:cover;background-position:50%;text-decoration:none!important;position:relative}.BoxBanners-BoxBanners__item--3w6OGaz4:hover .BoxBanners-BoxBanners__content--78cxLm_u{height:160px}@media (max-width:767px){.BoxBanners-BoxBanners__item--3w6OGaz4{margin:30px 0}}.BoxBanners-BoxBanners__content--78cxLm_u{display:flex;height:140px;flex-direction:column;align-items:center;justify-content:center;position:absolute;bottom:0;width:100%;font-size:22px;font-weight:600;transition-duration:.2s}.BoxBanners-BoxBanners__title--3fgi9m6M{display:block;margin-bottom:10px}.BoxBanners-BoxBanners__subtitle--L7hcvRBr{display:block;text-transform:uppercase;color:#f6ee4b}.BoxBanners-BoxBanners_theme_light--1ZpbNAYc .BoxBanners-BoxBanners__content--78cxLm_u{background:hsla(0,0%,100%,.8)}.BoxBanners-BoxBanners_theme_light--1ZpbNAYc .BoxBanners-BoxBanners__title--3fgi9m6M{color:#444}.BoxBanners-BoxBanners_theme_dark--2t3ItiWJ .BoxBanners-BoxBanners__content--78cxLm_u{background:rgba(0,0,0,.7)}.BoxBanners-BoxBanners_theme_dark--2t3ItiWJ .BoxBanners-BoxBanners__title--3fgi9m6M{color:#fff}@media (max-width:767px){.BoxBanners-BoxBanners--3icTObZW{display:block}.BoxBanners-BoxBanners__item--3w6OGaz4{display:block;height:220px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"BoxBanners": "BoxBanners-BoxBanners--3icTObZW",
	"BoxBanners__item": "BoxBanners-BoxBanners__item--3w6OGaz4",
	"BoxBanners__content": "BoxBanners-BoxBanners__content--78cxLm_u",
	"BoxBanners__title": "BoxBanners-BoxBanners__title--3fgi9m6M",
	"BoxBanners__subtitle": "BoxBanners-BoxBanners__subtitle--L7hcvRBr",
	"BoxBanners_theme_light": "BoxBanners-BoxBanners_theme_light--1ZpbNAYc",
	"BoxBanners_theme_dark": "BoxBanners-BoxBanners_theme_dark--2t3ItiWJ"
};
module.exports = ___CSS_LOADER_EXPORT___;
