// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Box-Box--1i3sY-5S{display:block;border-radius:3px}.Box-Box_size_small--33-ThPSf{padding:5px}.Box-Box_size_medium--2wDHi5RH{padding:15px}.Box-Box_size_large--1KhST_by{padding:30px}.Box-Box_theme_light--1R1TPVWZ{box-shadow:1px 1px 5px rgba(0,0,0,.1)}.Box-Box_theme_dark--3RktNFd6{border:1px solid #212121}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Box": "Box-Box--1i3sY-5S",
	"Box_size_small": "Box-Box_size_small--33-ThPSf",
	"Box_size_medium": "Box-Box_size_medium--2wDHi5RH",
	"Box_size_large": "Box-Box_size_large--1KhST_by",
	"Box_theme_light": "Box-Box_theme_light--1R1TPVWZ",
	"Box_theme_dark": "Box-Box_theme_dark--3RktNFd6"
};
module.exports = ___CSS_LOADER_EXPORT___;
