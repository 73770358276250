<template>
  <div :class="$style['Filters']">
    <div :class="$style['Filters__title']">
      Filters:
    </div>
    <slot />
    <div :class="$style['Filters__clear']">
      <Button color="primary" plain @click="$emit('clear')">
        <i class="el-icon-refresh-left el-icon--left" />
        Clear Filters
      </Button>
    </div>
  </div>
</template>

<script>
import Button from '../Button'
export default {
  name: 'Filters',
  components: {
    Button,
  },
}
</script>
<style lang="scss" module>
.Filters {
  position: relative;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  &__clear {
    margin-left: 15px;
  }
  &__title {
    margin-right: 10px;
    font-weight: $--fw-medium;
  }
  @media (max-width: $--sm) {
    display: block;
    &__title {
      display: none;
    }
    &__clear {
      margin-left: 0;
      button {
        width: 100%;
      }
    }
  }
}
</style>
