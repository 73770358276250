<template>
  <div :class="rootClasses" @click="click">
    <div v-if="img" :class="$style['FiltersBox__img']">
      <img :data-src="img" :alt="title" class="lazyload">
    </div>
    <div v-if="title" :class="$style['FiltersBox__title']">
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'FiltersBox',
  props: {
    id: {
      type: String,
      default: null,
    },
    img: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    value: {
      type: [Array, String],
      default: null,
    },
  },
  computed: {
    rootClasses() {
      return {
        [this.$style.FiltersBox]: true,
        [this.$style.FiltersBox_active]: this.isActive,
      }
    },
    isActive() {
      return this.value && this.value.some(id => id === this.id)
    },
  },
  methods: {
    click() {
      if (!this.id || !this.value) return
      const exists = this.value.includes(this.id)
      const newValue = exists
        ? this.value.filter(val => val !== this.id)
        : [...this.value, this.id]
      this.$emit('input', newValue)
    },
  },
}
</script>
<style lang="scss" module>
.FiltersBox {
  cursor: pointer;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &:hover {
    border-color: rgba(0, 0, 0, 0.2);
  }
  &_active {
    border-color: $--color-primary !important;
  }
  &__title {
    color: $--color-grey;
    margin-top: 10px;
    font-size: 14px;
  }
  &__img {
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: center;
    img {
      max-height: 50px;
      max-width: 100%;
    }
  }
}
</style>
