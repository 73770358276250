<template>
  <div
    :class="rootClasses"
    :style="{ backgroundImage: backgroundImage ? `url(${backgroundImage})` : null }"
  >
    <div :class="$style['CtaBanner__img']">
      <img v-if="img" :data-src="img" alt="" class="lazyload">
    </div>
    <div :class="contentClasses">
      <div>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CtaBanner',
  props: {
    align: {
      type: String,
      default: 'left',
    },
    theme: {
      type: String,
      default: process.env.theme || 'light',
    },
    img: {
      type: String,
      default: null,
    },
    backgroundImage: {
      type: String,
      default: null,
    },
  },
  computed: {
    rootClasses() {
      return {
        [this.$style.CtaBanner]: true,
        [this.$style[`CtaBanner_theme_${this.theme}`]]: this.theme,
      }
    },
    contentClasses() {
      return {
        [this.$style.CtaBanner__content]: true,
        [this.$style[`CtaBanner__content_${this.align}`]]: this.align,
      }
    },
  },
}
</script>
<style lang="scss" module>
.CtaBanner {
  $c: &;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-position: center center;
  &__content {
    padding: 30px;
    order: 1 !important;
  }
  &__img {
    text-align: center;
    order: 3;
    line-height: 0;
    img {
      max-width: 100%;
    }
  }
  &_theme {
    &_light {
      background-color: $--color-grey-lightest;
      #{$c}__content {
        background: rgba($--color-grey-lightest, 0.7);
      }
    }
    &_dark {
      background-color: $--color-grey-darker;
      color: #fff;
      #{$c}__content {
        background: rgba($--color-grey-darker, 0.7);
      }
    }
  }
  @media (min-width: $--md) {
    flex-direction: row;
    min-height: 480px;
    &__content {
      display: flex;
      align-items: center;
      padding: 50px 70px;
      width: 60%;
      &_left {
        order: 1;
      }
      &_right {
        order: 3;
      }
    }
    &__img {
      position: relative;
      width: 40%;
      img {
        position: absolute;
        left: 50%;
        margin-left: -200px;
        bottom: 0;
        line-height: 0;
      }
    }
  }
}
</style>
