<template>
  <div :class="$style['Rate']">
    <button type="button" :class="likeClasses" @click="$emit('like')">
      <Icon name="like" />
    </button>
    <button type="button" :class="dislikeClasses" @click="$emit('dislike')">
      <Icon name="dislike" />
    </button>
    <div :class="$style['Rate__score']">
      {{ score }} likes this {{ type }}
    </div>
  </div>
</template>

<script>
import Icon from '../Icon'
export default {
  name: 'Rate',
  components: {
    Icon,
  },
  props: {
    score: {
      type: String,
      default: null,
    },
    liked: {
      type: Boolean,
      default: false,
    },
    disliked: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'video',
    },
  },
  computed: {
    likeClasses() {
      return {
        [this.$style.Rate__like]: true,
        [this.$style.Rate__like_active]: this.liked,
      }
    },
    dislikeClasses() {
      return {
        [this.$style.Rate__dislike]: true,
        [this.$style.Rate__dislike_active]: this.disliked,
      }
    },
  },
}
</script>
<style lang="scss" module>
.Rate {
  display: flex;
  align-items: center;
  button {
    cursor: pointer;
    display: flex;
    background-color: transparent;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    outline: none;
    margin-right: 5px;
    &:active {
      box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
    }
    svg {
      width: 16px;
      height: 16px;
    }
  }
  &__like {
    border: 1px solid $--color-success;
    svg {
      fill: $--color-success;
    }
    &:hover,
    &_active {
      background: $--color-success !important;
      border-color: $--color-success;
      svg {
        fill: #fff;
      }
    }
  }
  &__dislike {
    border: 1px solid $--color-grey-light;
    svg {
      fill: $--color-grey;
    }
    &:hover {
      border-color: $--color-danger;
      svg {
        fill: $--color-danger;
      }
    }
    &_active {
      background: $--color-danger !important;
      border-color: $--color-danger;
      svg {
        fill: #fff !important;
      }
    }
  }
  &__score {
    color: $--color-grey;
    margin-left: 10px;
    font-size: 14px;
  }
}
</style>
