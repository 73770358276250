<template>
  <div v-if="models" :class="$style['ModelsList']">
    <div v-for="model in models" :key="model.id" :class="$style['ModelsList__el']">
      <ModelsListItem :model="model" :theme="theme" />
    </div>
  </div>
</template>

<script>
import ModelsListItem from './ModelsListItem'
export default {
  name: 'ModelsList',
  components: {
    ModelsListItem,
  },
  props: {
    models: {
      type: Array,
      default: null,
    },
    theme: {
      type: String,
      default: process.env.theme || 'light',
    },
  },
}
</script>
<style lang="scss" module>
.ModelsList {
  $c: &;
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
  flex-wrap: wrap;
  &__el {
    padding: 0 10px;
    flex: 1 1 25%;
    width: 25%;
    margin-bottom: 20px;
  }
  @media (max-width: $--md - 1) {
    &__el {
      flex: 1 1 50%;
      width: 50%;
    }
  }
  @media (max-width: $--sm - 1) {
    &__el {
      flex: 1 1 auto;
      width: 100%;
      + #{$c}__el {
        margin-top: 20px;
      }
    }
  }
}
</style>
