<template>
  <div v-if="scene" :class="rootClasses">
    <div :class="$style['ScenesListItem__box']" @click="linkToScene()">
      <span :class="$style['ScenesListItem__link']">
        <img :data-src="imgSrc" :alt="scene.title" class="lazyload">
        <span :class="$style['ScenesListItem__play']">
          <Icon name="play" color="white" size="medium" />
        </span>
        <span :class="$style['ScenesListItem__labels']">
          <span v-if="site && showSite" :class="$style['ScenesListItem__label']">
            {{ site.name }}
          </span>
          <span :class="$style['ScenesListItem__label']">
            {{ scene.quality }}
          </span>
        </span>
      </span>
      <span :class="$style['ScenesListItem__info']">
        <span :class="$style['ScenesListItem__title']">
          <span>{{ scene.title }}</span>
        </span>
        <span v-if="scene.durationText" :class="$style['ScenesListItem__time']">
          <Badge :text="scene.durationText" :theme="theme" />
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import Badge from '../Badge'
import Icon from '../Icon'
export default {
  name: 'ScenesListItem',
  components: {
    Badge,
    Icon,
  },
  props: {
    scene: {
      type: Object,
      default: null,
    },
    theme: {
      type: String,
      default: process.env.theme || 'light',
    },
    showSite: {
      type: Boolean,
      detault: false,
    },
    customSceneUrl: {
      type: String,
      default: null,
    },
  },
  computed: {
    sceneUrl() {
      if (this.customSceneUrl) return this.customSceneUrl
      if (!this.scene) return null
      const url = this.scene.urls.urlPath
      const id = this.site && this.site.id
      return this.showSite && id ? `${url}?siteId=${id}` : url
    },
    site() {
      return (
        this.scene &&
        this.scene.sites &&
        this.scene.sites.length &&
        this.scene.sites[0]
      )
    },
    rootClasses() {
      return {
        [this.$style.ScenesListItem]: true,
        [this.$style[`ScenesListItem_theme_${this.theme}`]]: this.theme,
      }
    },
    imgSrc() {
      return (
        this.scene.urls.thumbnailUrl ||
        this.imageUrl('placeholders/scene.png', false)
      )
    },
  },
  methods: {
    linkToScene() {
      if (this.customSceneUrl) {
        window.location.href = this.customSceneUrl
      } else {
        this.$router.push(this.sceneUrl)
      }
    },
  },
}
</script>
<style lang="scss" module>
.ScenesListItem {
  $c: &;
  display: block;
  a {
    text-decoration: none !important;
  }
  &__box {
    cursor: pointer;
  }
  &__link {
    display: block;
    position: relative;
    line-height: 1;
    img {
      position: relative;
      z-index: 1;
      width: 100%;
    }
  }
  &__labels {
    display: flex;
    position: absolute;
    top: 10px;
    right: 10px;
    left: 10px;
    z-index: 2;
    justify-content: flex-end;
  }
  &__label {
    display: block;
    background: rgba(#000000, 0.75);
    padding: 5px 10px;
    border-radius: 3px;
    color: #fff;
    font-size: 14px;
    font-weight: $--fw-medium;
    margin-left: 5px;
  }
  &__info {
    display: flex;
    align-items: center;
    margin-top: 5px;
  }
  &__title {
    flex: 1;
    font-size: 14px;
    font-weight: $--fw-medium;
    position: relative;
    height: 27px;
    > span {
      position: absolute;
      width: 100%;
      height: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 27px;
      line-height: 27px;
    }
  }
  &__time {
    white-space: nowrap;
    padding-left: 10px;
  }
  &__play {
    opacity: 0;
    transition-duration: 0.3s;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -32px;
    margin-top: -32px;
    z-index: 2;
    width: 64px;
    height: 64px;
    background: rgba($--color-primary, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  &:hover {
    #{$c}__play {
      opacity: 1;
    }
  }
  &_theme {
    &_light {
      #{$c}__title {
        color: $--text-color;
      }
    }
    &_dark {
      #{$c}__title {
        color: $--color-grey-lightest;
      }
    }
  }
}
</style>
