<template>
  <el-button v-bind="$attrs" :plain="plain" :type="color" :class="rootClasses" v-on="$listeners">
    <slot />
  </el-button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    /**
     * Button ui color
     * `primary`
     */
    color: {
      type: String,
      default: 'default',
    },
    /**
     * Button width
     * `small, medium, large, full, full-mobile`
     */
    width: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: null,
    },
    plain: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    rootClasses() {
      return {
        [this.$style.Button]: true,
        [this.$style[`Button_width_${this.width}`]]: this.width,
        [this.$style[`Button_size_${this.size}`]]: this.size,
      }
    },
  },
}
</script>
<style lang="scss" module>
.Button {
  text-transform: uppercase;
  &_width {
    &_small {
      min-width: 90px;
    }
    &_medium {
      min-width: 150px;
    }
    &_large {
      min-width: 180px;
    }
    &_full {
      width: 100%;
    }
    &_full-mobile {
      @media (max-width: $--sm - 1) {
        width: 100%;
      }
    }
  }
  &_size {
    &_large {
      height: 48px;
    }
    &_mini {
      height: 28px;
      padding: 7px 15px !important;
      font-size: 12px;
      text-transform: none;
      @media (max-width: $--sm - 1) {
        margin: 0 15px 15px 0;
      }
    }
  }
}
</style>
