<template>
  <div :class="$style['FiltersDropdown']">
    <div :class="$style['FiltersDropdown__backdrop']" />
    <div v-click-outside="outClick" :class="contentClasses">
      <div v-if="title" :class="$style['FiltersDropdown__title']">
        {{ title }}
      </div>
      <div :class="$style['FiltersDropdown__main']">
        <slot />
      </div>
      <div :class="$style['FiltersDropdown__actions']">
        <Button color="primary" @click.stop="$emit('apply')">
          Apply
        </Button>
        <Button @click="$emit('close')">
          Cancel
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
import Button from '../Button'
export default {
  name: 'FiltersDropdown',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    Button,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isActive: false,
    }
  },
  computed: {
    contentClasses() {
      return {
        [this.$style.FiltersDropdown__content]: true,
        [this.$style.FiltersDropdown__content_active]: this.isActive,
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.isActive = true
    }, 100)
  },
  methods: {
    outClick() {
      if (!this.isActive) return
      this.$emit('close')
    },
  },
}
</script>
<style lang="scss" module>
.FiltersDropdown {
  position: absolute;
  z-index: 999;
  top: 100%;
  width: 100%;
  right: 0;
  &__main {
    max-height: 300px;
    overflow-y: auto;
    // @media (max-width: $--sm - 1) {
    //   overflow-y: auto;
    // }
  }
  &__content {
    position: relative;
    z-index: 999;
    background: #fff;
    color: $--text-color;
    padding: 20px;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.4);
    margin-top: 10px;
    border-radius: 5px;
    transform: translateY(15px);
    opacity: 0;
    transition-duration: 0.3s;
    &_active {
      opacity: 1;
      transform: translateY(0);
    }
  }
  &__backdrop {
    position: fixed;
    z-index: 998;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
  }
  &__title {
    margin-bottom: 15px;
    font-size: 18px;
  }
  &__actions {
    margin-top: 20px;
  }
  @media (min-width: $--sm) {
    min-width: 600px;
  }
}
</style>
