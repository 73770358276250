// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Block-Block--pBQZmiq_{display:block}.Block-Block_top_small--yxhgNo7_{margin-top:5px}.Block-Block_top_medium--15N-LgxV{margin-top:15px}.Block-Block_top_large--2pz9-IYh{margin-top:30px}.Block-Block_bottom_small--3J99vzlA{margin-bottom:5px}.Block-Block_bottom_medium--1B0XEzjM{margin-bottom:15px}.Block-Block_bottom_large--1G0LIOQf{margin-bottom:30px}.Block-Block_left_small--35E3Fl1w{margin-left:5px}.Block-Block_left_medium--_vxGYwDp{margin-left:15px}.Block-Block_left_large--3Fo2k1vC{margin-left:30px}.Block-Block_right_small--2BEiXO8X{margin-right:5px}.Block-Block_right_medium--2Nw3z0ih{margin-right:15px}.Block-Block_right_large--3id2V4yO{margin-right:30px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Block": "Block-Block--pBQZmiq_",
	"Block_top_small": "Block-Block_top_small--yxhgNo7_",
	"Block_top_medium": "Block-Block_top_medium--15N-LgxV",
	"Block_top_large": "Block-Block_top_large--2pz9-IYh",
	"Block_bottom_small": "Block-Block_bottom_small--3J99vzlA",
	"Block_bottom_medium": "Block-Block_bottom_medium--1B0XEzjM",
	"Block_bottom_large": "Block-Block_bottom_large--1G0LIOQf",
	"Block_left_small": "Block-Block_left_small--35E3Fl1w",
	"Block_left_medium": "Block-Block_left_medium--_vxGYwDp",
	"Block_left_large": "Block-Block_left_large--3Fo2k1vC",
	"Block_right_small": "Block-Block_right_small--2BEiXO8X",
	"Block_right_medium": "Block-Block_right_medium--2Nw3z0ih",
	"Block_right_large": "Block-Block_right_large--3id2V4yO"
};
module.exports = ___CSS_LOADER_EXPORT___;
