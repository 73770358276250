<template>
  <div :class="rootClasses">
    <Container>
      <div :class="$style['Header__container']">
        <button type="button" :class="$style['Header__mobile-button']" @click="$emit('toggleMenu')">
          <Icon name="menu" size="large" />
        </button>
        <div :class="$style['Header__logo']">
          <slot name="logo" />
        </div>
        <div :class="$style['Header__content']">
          <slot name="content" />
        </div>
      </div>
    </Container>
  </div>
</template>

<script>
import Icon from '../Icon'
import Container from '../Container'
export default {
  name: 'Header',
  components: {
    Icon,
    Container,
  },
  props: {
    theme: {
      type: String,
      default: process.env.theme || 'light',
    },
  },
  computed: {
    rootClasses() {
      return {
        [this.$style.Header]: true,
        [this.$style[`Header_theme_${this.theme}`]]: this.theme,
      }
    },
  },
}
</script>
<style lang="scss" module>
.Header {
  $c: &;
  top: 0;
  position: sticky;
  width: 100%;
  z-index: 999;
  &__mobile-button {
    display: none;
  }
  &__container {
    height: 70px;
    display: flex;
    align-items: center;
  }
  &__logo {
    img {
      max-width: 180px;
      max-height: 50px;
    }
  }
  &__content {
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
    text-transform: uppercase;
  }
  &_theme {
    &_light {
      background: #fff;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }
    &_dark {
      background: $--color-grey-darkest;
    }
  }
  @media (max-width: $--sm - 1) {
    &__logo {
      flex: 1 1 auto;
      text-align: center;
    }
    &__content {
      flex: 0 0 auto;
    }
    &__mobile-button {
      display: flex;
      background: none;
      border: none;
      outline: none;
      cursor: pointer;
      svg {
        transition-duration: 0.2s;
      }
    }
    &_theme {
      &_light #{$c}__mobile-button {
        svg {
          fill: rgba($--color-grey, 0.5);
        }
        &:hover {
          svg {
            fill: $--color-primary !important;
          }
        }
      }
      &_dark #{$c}__mobile-button {
        svg {
          fill: rgba(#fff, 0.5);
        }
        &:hover {
          svg {
            fill: #fff;
          }
        }
      }
    }
  }
}
</style>
