<template>
  <div :class="rootClasses">
    <div :class="$style['ListDefinition__title']">
      {{ title }}
    </div>
    <div :class="$style['ListDefinition__main']">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListDefinition',
  props: {
    title: {
      type: String,
      default: null,
    },
    block: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    rootClasses() {
      return {
        [this.$style.ListDefinition]: true,
        [this.$style.ListDefinition_block]: this.block,
      }
    },
  },
}
</script>

<style lang="scss" module>
.ListDefinition {
  $c: &;
  &__title {
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 5px;
  }
  + .ListDefinition {
    margin-top: 15px;
  }
  &_block {
    #{$c}__title {
      padding-bottom: 2px;
    }
  }
  @media (min-width: $--sm) {
    &:not(#{$c}_block) {
      display: flex;
      #{$c}__title {
        width: 150px;
        flex-shrink: 0;
        padding-top: 3px;
        padding-bottom: 0;
      }
      #{$c}__main {
        float: 1 1 auto;
      }
    }
  }
}
</style>
