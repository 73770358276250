<template>
  <div v-if="menu && menu.length" :class="rootClasses">
    <div :class="$style.HeaderMenu__content">
      <nuxt-link
        v-for="item in menu"
        :key="item.key"
        :to="`/${item.key === 'home' ? '' : item.key}`"
        :class="itemClass(item.key)"
        :show="false"
      >
        <span v-if="!item.requiresLogin || isLoggedIn">
          {{ item.title }}
        </span>
      </nuxt-link>
      <a v-if="this.$siteContent('globals.liveUrl') && isLoggedIn" :href="this.$siteContent('globals.liveUrl')" :class="itemClass('live')" target="_blank">
        Live
      </a>
      <div v-if="$mq === 'mobile'" :class="$style.HeaderMenu__footer">
        <Button color="primary" width="full" @click="goToJoinNowUrl">
          Join Now
        </Button>
      </div>
    </div>
    <div :class="$style.HeaderMenu__backdrop" @click="$emit('toggleMenu')" />
  </div>
</template>

<script>
import Button from '../Button'
import CurrentUserMixin from '~/mixins/currentUser'

export default {
  name: 'HeaderMenu',
  components: {
    Button,
  },
  mixins: [CurrentUserMixin],
  props: {
    mobileMenu: {
      type: Boolean,
      default: false,
    },
    menu: {
      type: Array,
      default: null,
    },
    currentSection: {
      type: String,
      default: null,
    },
    theme: {
      type: String,
      default: process.env.theme || 'light',
    },
  },
  computed: {
    rootClasses() {
      return {
        [this.$style.HeaderMenu]: true,
        [this.$style.HeaderMenu_active]: this.mobileMenu,
        [this.$style[`HeaderMenu_theme_${this.theme}`]]: this.theme,
      }
    },
  },
  methods: {
    itemClass(key) {
      return {
        [this.$style.HeaderMenu__item]: true,
        [this.$style.HeaderMenu__item_active]: this.currentSection === key,
      }
    },
  },
}
</script>
<style lang="scss" module>
.HeaderMenu {
  $c: &;
  &__content {
    display: flex;
  }
  &__item {
    display: flex;
    height: 30px;
    align-items: center;
    padding: 5px 15px;
    border-radius: 500px;
    transition-duration: 0.2s;
    font-weight: $--fw-medium;
    text-decoration: none !important;
    &:hover {
      color: $--header-nav-color !important;
    }
    &_active {
      color: $--header-nav-color !important;
    }
  }
  &_theme {
    &_light #{$c}__item {
      color: $--color-grey;
      &#{$c}__item_active {
        color: $--header-nav-color;
      }
    }
    &_dark #{$c}__item {
      color: rgba(255, 255, 255, 0.5);
      &#{$c}__item_active {
        background: rgba($--color-grey-light, 0.1);
      }
    }
  }
  @media (max-width: $--sm - 1) {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
    transition-duration: 0.3s;
    opacity: 0;
    visibility: hidden;
    &__content {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      bottom: 0;
      display: block;
      width: 260px;
      padding: 10px 0;
      box-shadow: 10px 0 30px rgba(0, 0, 0, 0.2);
      transform: translate(-260px, 0);
      transition-duration: 0.3s;
    }
    &__footer {
      padding: 15px;
    }
    &_active {
      opacity: 1;
      visibility: visible;
      #{$c}__content {
        transform: translate(0, 0);
      }
    }
    &_theme {
      &_light #{$c}__content {
        background: #fff;
      }
      &_dark #{$c}__content {
        background: $--color-grey-darkest;
      }
    }
    &__backdrop {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.8);
    }
    &__item {
      height: 48px;
      border-radius: 0;
    }
  }
}
</style>
