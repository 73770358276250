import cloneDeep from 'lodash.clonedeep'

export default {
  data() {
    return {
      isFormSubmitting: false,
    }
  },
  methods: {
    validationErrorNotification() {
      // this.$store.dispatch('setNotification', {
      //   text: 'Please correct the form errors before submitting',
      //   color: 'error',
      // })
    },
    submitForm(name = null, skipValidation = false) {
      this.isFormSubmitting = true
      const opts =
        typeof name === 'string'
          ? this.$options.graphqlForm[name]
          : this.$options.graphqlForm

      if (opts.scrollTop !== false) {
        window.scrollTo(0, 0)
      }

      // TODO: For now we skip validation til we can figure it out
      // this.errors.clear()
      skipValidation = true
      if (!skipValidation) {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.$_graphqlForm_submitGraphQL(opts)
          } else {
            this.isFormSubmitting = false
            this.validationErrorNotification()
            // in case we want to process it somehow or notify user
            opts.validatorError && opts.validatorError.call(this)
          }
        })
      } else {
        this.$_graphqlForm_submitGraphQL(opts)
      }
    },
    stripGraphqlAttributes(val, recursed) {
      if (!val) return {}
      if (!recursed) val = cloneDeep(val)
      if (val && val.__typename) delete val.__typename
      if (val && val['Symbol(id)']) delete val['Symbol(id)']
      Object.keys(val).forEach(k => {
        if (val[k] && typeof val[k] === 'object')
          this.stripGraphqlAttributes(val[k], true)
      })
      return val
    },
    populateErrorsFromExistingBag(bag) {
      bag.items.forEach(x => {
        this.errors.add({
          field: x.field,
          msg: x.msg,
          rule: x.rule,
        })
        this.$validator.flag(x.field, { valid: false })
      })
    },
    $_graphqlForm_submitGraphQL(opts) {
      let query = opts.query
      const refetchQueries = opts.refetch ? opts.refetch.call(this) : []
      if (typeof query === 'function') {
        query = query.call(this, opts)
      }
      this.$apollo
        .mutate({
          mutation: query,
          variables: {
            input: opts.variables.call(this),
          },
          refetchQueries,
          update: opts.update,
          // On success call the success method
        })
        .then(res => {
          opts.success.call(this, res.data)
          this.isFormSubmitting = false
          // On failure populate vee validate with error data
        })
        .catch(error => {
          // const locale = this.$validator.locale
          // // extracted for using recursively
          // const processErrors = (fields, ns = '') => {
          //   for (const [field, errors] of Object.entries(fields)) {
          //     if (errors instanceof Array) {
          //       const key = ns ? ns + '.' + field : field
          //       const error = errors[0]
          //       let errorMessage = error
          //       if (opts.errorMessages && opts.errorMessages[error]) {
          //         errorMessage = opts.errorMessages[error]
          //       } else if (
          //         this.$validator.dictionary.hasMessage(locale, error)
          //       ) {
          //         errorMessage = this.$validator.dictionary
          //           .getMessage(locale, error)
          //           .replace('{{field}}', field)
          //       }
          //       this.errors.add({
          //         field: key,
          //         msg: errorMessage,
          //         rule: error,
          //       })
          //       this.$validator.flag(key, { valid: false })
          //     } else {
          //       ns = ns ? ns + '.' + field : field
          //       processErrors(errors, ns)
          //     }
          //   }
          // }
          if (
            error.graphQLErrors &&
            error.graphQLErrors.length &&
            error.graphQLErrors[0].fields
          ) {
            // here we call processErrors recursively because errors can be nested
            // const fields = error.graphQLErrors[0].fields
            // processErrors(fields, '')
            // in case we want to process it somehow or notify user
            opts.graphQLError && opts.graphQLError.call(this, error)
            if (opts.skipNotification !== true) {
              this.validationErrorNotification()
            }
          } else {
            // handle 500 error from server
            opts.error && opts.error.call(this, error)
          }
          this.isFormSubmitting = false
        })
    },
  },
}
