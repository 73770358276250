<template>
  <div v-if="features" :class="$style['CtaBannerFeatures']">
    <div v-for="(item, i) in features" :key="i" :class="$style['CtaBannerFeatures__item']">
      <div :class="$style['CtaBannerFeatures__icon']">
        <Icon :name="item.icon" color="primary" />
      </div>
      <div :class="$style['CtaBannerFeatures__title']">
        {{ item.title }}
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '../Icon'
export default {
  name: 'CtaBannerFeatures',
  components: {
    Icon,
  },
  props: {
    features: {
      type: Array,
      default: null,
    },
  },
}
</script>
<style lang="scss" module>
.CtaBannerFeatures {
  flex-wrap: wrap;
  margin-bottom: 40px;
  &__icon {
    margin-right: 7px;
  }
  &__title {
    font-size: 14px;
    font-weight: $--fw-bold;
    padding-top: 2px;
  }
  &__item {
    display: flex;
    flex: 1 1 33.333333%;
    padding-right: 10px;
    line-height: 1;
  }
  @media (min-width: $--sm) {
    display: flex;
  }
  @media (max-width: $--sm - 1) {
    margin-bottom: 20px;
    &__item {
      margin-bottom: 15px;
    }
  }
}
</style>
