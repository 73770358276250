<template>
  <div :class="$style['ActionBarItem']">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ActionBarItem',
}
</script>
<style lang="scss" module>
.ActionBarItem {
  @media (min-width: $--sm) {
    + .ActionBarItem {
      margin-left: 30px;
    }
  }
  @media (max-width: $--sm - 1) {
    + .ActionBarItem {
      margin-top: 15px;
    }
  }
}
</style>
