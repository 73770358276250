/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'play': {
    width: 16,
    height: 16,
    viewBox: '0 0 41.999 41.999',
    data: '<path pid="0" d="M36.068 20.176l-29-20A1 1 0 005.5.999v40a1 1 0 001.568.823l29-20a.999.999 0 000-1.646z"/>'
  }
})
